import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, ConfigProvider, Form, Input, Modal, Select, Tooltip } from "antd";
import type { SelectProps } from 'antd/es/select';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Utility } from "../../helper/index";
import service from "../../service/api/common";
import { default as constant1, default as constants } from "../../settings/constants";
import { hideSidebar, resize } from '../../settings/resize';
import * as roles from '../../settings/roles';
import CountryList from "../reusableComponent/country";
import PaginationComponent from "../reusableComponent/pagination";
import SessionWarning from '../reusableComponent/sessionWarning';
import ShowEntries from "../reusableComponent/showEntries";
import LoadingSpinner from '../spinner/loading-spinner';

const req = {
  pageOffset: 1,
  pageLimit: 10,
  orgId: 0,
  locationId: -1,
  divisionId: -1,
  buildingId: -1,
  locationName: "",
  sortColumns: "locationName",
  sortDirection: "ASC"
}

const LocationMaintenance: FC<any> = ({ globalData, refresh }) => {
  const [reqBody, setReqBody] = useState<any>(req)
  const [show, setShow] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [toggleDelete, setToggleDelete] = useState(false)
  const [url, setUrl] = useState<string>("")
  const [Title, setTitle] = useState<string>("")
  const [id, setId] = useState<number>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [toUpdate, setToUpdate] = useState<any>({ locId: null, orgId: null, orgName: "", locationName: "", locationAddress: "", locationCountry: "", contactName: "", contactEmail: "", contactTelephone: "", deleted: "", dateDeleted: "", dateUpdated: "", divisionName: "", divisionId: "" })
  const [isLocUpdated, setIsLocUpdated] = useState<boolean>(false)
  const [isLocDeleted, setIsLocDeleted] = useState<boolean>(false)
  const [sort, setSort] = useState<any>({ org: false, locationName: false, locationAddress: false, locationCountry: false, contactName: false, email: false, contactTelephone: false, deleted: false })
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const tabelRef = useRef<HTMLDivElement>(null)
  const [orgList, setOrgList] = useState<null[]>([])
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [divisions, setDivisions] = useState<any[]>([]);
  const [locQuery, setLocQuery] = useState<string>("")
  const [addpayload, setAddPayload] = useState<any>({
    selectedRole: localStorage?.UserType,
    selectedOrgId: Number(localStorage?.accountId),
    locationName: "",
    sortColumns: "locationName",
    sortDirection: "ASC"
  });
  const [initial, setInitial] = useState<boolean>(true);
  const [locationEmpty, setLocationEmpty] = useState<boolean>(false)

  const [form] = Form.useForm();
  const [updateForm] = Form.useForm()

  const [tableHeight, setTableHeight] = useState<any>(410)
  const filtersRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)

  const blueColor = "#004f9f"
  const grayColor = "#666666"
  const tooltipColor = "#7a8185"

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    let marginAndPaddings: any = 90
    let navHeight = localStorage.getItem('navHeight')
    let filtersHeight
    let paginationHeight
    if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 410 ? setTableHeight(410) : setTableHeight(tableHeight)
  };

  const showAddUser = () => {
    setShow(!show)
    form.resetFields()
  };

  async function getOrganization(orgid: any, divid: any, locid: any, siteid: any) {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    let url = constants.api.dashboard.getOrganizations;

    let payload = {
      "orgId": orgid,
      "divisionId": divid,
      "locationId": locid,
      "buildingId": siteid
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      const org = await response;
      setOrgList(org);
    } catch (error) {
      console.log("getOrgApiError:", error);
    }
  }

  const showUpdateLoc = (item: any) => {
    setIsModalOpen(true);
    const { locId, orgId, locationName, locationAddress, locationCountry, contactName, contactEmail, contactTelephone, orgName, divisionId, divisionName } = item;
    setToUpdate({ ...toUpdate, locId, locationName, locationAddress, locationCountry, contactName, contactEmail, contactTelephone, orgId, orgName, divisionId, divisionName });

    updateForm.setFieldsValue({
      u_locationName: locationName,
      u_address: locationAddress,
      u_countryName: locationCountry,
      u_contactName: contactName,
      email: contactEmail,
      u_contactTelephone: contactTelephone,
      u_orgName: orgName
    })
  };

  async function handleUpdate(values: any) {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    const { locId, orgId, orgName, divisionId } = toUpdate
    const { u_locationName, u_address, u_countryName, u_contactName, email, u_contactTelephone, u_orgName } = values
    const u_org = orgList?.find((item: any) => item.orgName === u_orgName)

    const toUpdateData = {
      locId: locId,
      locationName: u_locationName,
      orgId: u_org?.['orgId'] ? u_org?.['orgId'] : orgId,
      orgName: u_org?.['orgName'] ? u_org?.['orgName'] : orgName,
      locationAddress: u_address,
      locationCountry: u_countryName,
      contactName: u_contactName,
      contactEmail: email,
      contactTelephone: u_contactTelephone,
      divisionId: divisionId
    }

    try {
      const response = await service({
        url: constant1.api.location.putLocation,
        method: "PUT",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: toUpdateData
      });
      setIsModalOpen(false);
      if (response.status === "FAILURE") {
        setMessage(response.errorMessage)
      } else {
        setMessage('Location Updated Successfully')
      }
      setIsLocUpdated(true)
    } catch (error: any) {
      if (error.status === 400) {
        const { data } = error
        if (data.contactName) {
          updateForm.setFields([{ name: 'contact_Name', errors: [data.contactName] }])
        }
        if (data.locationName) {
          updateForm.setFields([{ name: 'locationName', errors: [data.locationName] }])
        }
        if (data.contactTelephone) {
          updateForm.setFields([{ name: 'contactTelephone', errors: [data.contactTelephone] }])
        }
        if (data.contactEmail) {
          updateForm.setFields([{ name: 'contactEmail', errors: [data.contactEmail] }])
        }
      }
      console.log("UpdateLoc:", error);
    }
  };

  const isLocationUpdated = () => {
    setIsLocUpdated(false)
    setReqBody({ ...reqBody, pageOffset: 1 })
    window.location.reload()
    // getLocationData({ ...reqBody, pageOffset: 1 })
    // setCurrent(1)
  };

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('locationMaintenanceR_W')) window.history.back()
    }

    var userInfo: any = Utility.getUserInfo();
    var loginDealerId = userInfo.userId;
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    setInitial(true);
    resize();
    hideSidebar();
    setUrl(constants.api.location.postLocation)

    if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
      getOrganization(0, 0, 0, 0);
    } else {
      getOrganization(loginDealerId, 0, 0, 0);
    }

    // getDivisions(data?.orgId)
    setReqBody({ ...reqBody, orgId: data?.orgId, locationId: data?.locationId, divisionId: data?.divId, buildingId: data?.buildingId })
    getLocationData({ ...reqBody, orgId: data?.orgId, locationId: data?.locationId, divisionId: data?.divId, buildingId: data?.buildingId });
    getCount({ ...reqBody, orgId: data?.orgId, locationId: data?.locationId, divisionId: data?.divId, buildingId: data?.buildingId });
    setInitial(false);
  }, []);

  useEffect(() => {
    if (initial === false) {
      setLocQuery("")
      setReqBody({ ...reqBody, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, locationName: "" });
      getLocationData({ ...reqBody, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, pageOffset: 1, locationName: "" });
      getCount({ ...reqBody, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, locationName: "" });
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId]);

  useEffect(() => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    if (initial === false) {
      if (refresh === 0) return
      getLocationData({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
      getCount({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
    }

  }, [refresh])

  async function getLocationData(info: any) {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    setIsShowSpinner(true);
    try {
      const response: any = await service({
        url: constants.api.location.getLocationByOrg,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: info
      });
      setTableData(response);
      setIsShowSpinner(false)
    } catch (error) {
      console.error("getLocation:", error);
      setIsShowSpinner(false)
    }
  };

  async function getDivisions(orgId: any) {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    var url = constants.api.location.getDivisions;
    let payload = { orgId: orgId };

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setDivisions(response);
    } catch (error) {
      console.error("getDivisionsApiError:", error);
    }
  }

  const getCount = async (info: any) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    try {
      const response = await service({
        url: constants.api.location.getLocationByOrgCount,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: info
      });
      setTotalCount(response)
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (pageNumber: number) => {
    setReqBody({ ...reqBody, pageOffset: pageNumber });
    getLocationData({ ...reqBody, pageOffset: pageNumber })
    setCurrent(pageNumber);
  }

  const onSelect = (getPageSize: string) => {
    const numPageSize = Number(getPageSize)
    setPageSize(numPageSize)
    setCurrent(1);
    setReqBody({ ...reqBody, pageLimit: Number(getPageSize), pageOffset: 1})
    getLocationData({ ...reqBody, pageLimit: Number(getPageSize), pageOffset: 1})
  }

  const validateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  };

  const validateUpdateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  };

  async function onAddFinish(value: any) {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    let token: string = userInfo.authToken;

    if (localStorage?.UserType === roles.COMPANY_ADMIN) {
      value.orgId = userId
      value.divisionId = Number(localStorage?.divisionId)
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", "content-type": "application/json", Authorization: "Bearer " + token },
        data: value,
      });
      if (response.status === "SUCCESS") {
        setShow(false)
        setMessage('Location Submitted Successfully')
        setIsLocUpdated(true)
      }
      if (response.status === "FAILURE" && response.errorMessage === "Location name already exists") {
        form.setFields([{ name: 'locationName', errors: [response.errorMessage] }])
      }
    } catch (error: any) {
      if (error.status === 400) {
        const { data } = error
        if (data.contactName) {
          form.setFields([{ name: 'contactName', errors: [data.contactName] }])
        }
        if (data.locationName) {
          form.setFields([{ name: 'locationName', errors: [data.locationName] }])
        }
        if (data.contactTelephone) {
          form.setFields([{ name: 'contactTelephone', errors: [data.contactTelephone] }])
        }
        if (data.contactEmail) {
          form.setFields([{ name: 'contactEmail', errors: [data.contactEmail] }])
        }
      }
    }
  }

  async function deleteLocList(locId: number) {
    setToggleDelete(!toggleDelete)
    setId(locId)
  }

  const okDeleteLoc = async () => {
    setToggleDelete(!toggleDelete);
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    try {
      const response = await service({
        url: constants.api.location.deleteLocation + id,
        method: "DELETE",
        headers: { accept: "*/*", "content-type": "application/json", Authorization: "Bearer " + token },
      });

      if (response.status === "SUCCESS") {
        setTitle("Location Deactivated Successfully")
        setIsLocDeleted(true)
      } else if (response.status === "FAILURE") {
        setMessage(response.errorMessage)
        setTitle(response.errorMessage)
        setIsLocDeleted(true)
      }
    } catch (error) {
      console.warn("dealerDelete:", error)
    }
  };

  const okLocDeleted = () => {
    setIsLocDeleted(false)
    setReqBody({ ...reqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
    window.location.reload()
  }

  const sortBy = (id: string) => {    
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case 'org':
        if (sort.org) {
          setReqBody({ ...reqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break

      case "locationName":
        if (sort.locationName) {
          setReqBody({ ...reqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'locationAddress':
        if (sort.locationAddress) {
          setReqBody({ ...reqBody, sortColumns: "locationAddress", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "locationAddress", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "locationAddress", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "locationAddress", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'locationCountry':
        if (sort.locationCountry) {
          setReqBody({ ...reqBody, sortColumns: "locationCountry", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "locationCountry", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "locationCountry", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "locationCountry", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'contactName':
        if (sort.contactName) {
          setReqBody({ ...reqBody, sortColumns: "contactName", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "contactName", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contactName", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "contactName", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'email':
        if (sort.email) {
          setReqBody({ ...reqBody, sortColumns: "contactEmail", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "contactEmail", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contactEmail", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "contactEmail", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break

      case 'contactTelephone':
        if (sort.contactTelephone) {
          setReqBody({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break

      case 'deleted':
        if (sort.deleted) {
          setReqBody({ ...reqBody, sortColumns: "deleted", sortDirection: "ASC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "deleted", sortDirection: "ASC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "deleted", sortDirection: "DESC", pageOffset: 1 })
          getLocationData({ ...reqBody, sortColumns: "deleted", sortDirection: "DESC", pageOffset: 1 });
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  const handleSearch = (value: string) => {
    let val = value ? value?.trim() : "";
    setLocQuery(val)
    setLocationEmpty(false)
    setTimeout(() => {
      if (value === "") {
        setReqBody({ ...reqBody, locationName: "", pageOffset: 1 })
        getLocationData({ ...reqBody, locationName: "", pageOffset: 1 })
        getCount({ ...reqBody, locationName: "", pageOffset: 1 })
        setCurrent(1)
      } else {
        setReqBody({ ...reqBody, locationName: val, pageOffset: 1 })
        getLocationData({ ...reqBody, locationName: val, pageOffset: 1 })
        getCount({ ...reqBody, locationName: val, pageOffset: 1 })
        setCurrent(1)
      }
    }, 1000)
  };

  const searchOnSelect = (value: string) => {
    setCurrent(1);
    setReqBody({ ...reqBody, locationName: value, pageOffset: 1 })
    getLocationData({ ...reqBody, locationName: value, pageOffset: 1 })
  };

  const onClearGetCall = () => { }

  const callLocationApi = () => {
    if (!locQuery) { setLocationEmpty(true) } else { setLocationEmpty(false) }
    if (!locQuery) return
    setReqBody({ ...reqBody, locationName: locQuery, pageOffset: 1 })
    getLocationData({ ...reqBody, locationName: locQuery, pageOffset: 1 })
    setCurrent(1)
  }

  const okButtonStyle = {
    background:blueColor,
    color: '#fff',
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className="container-fluid px-0 marginT">
        {localStorage.getItem('permissions')?.includes("locationadd_W") ? <>
          <div className=" row nav-filter align-items-center">
            <div className="col-12  tab_content-battery">
              <span onClick={showAddUser} className="d-flex align-items-center  ">
                {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}{" "}
                <span className="pl-2 paddingT">Add New Location</span>{" "}
              </span>
            </div>
          </div>
        </> : <></>}
        {show ? (
          <>
            <div
              className={show ? "show-user  add-user" : "hide-user add-user"}>
              <ConfigProvider
                theme={{ token: { colorPrimary: blueColor} }}>
                <Form
                  form={form}
                  onFinish={onAddFinish}
                  layout="vertical"
                  validateMessages={validateMessages}>
                  <div className="row">
                    {
                      (localStorage?.UserType === roles.PLATFORM_ADMIN || localStorage?.UserType === roles.DEALER_ADMIN) ?
                        <div className='col-6'>
                          <Form.Item
                            style={{ marginBottom: "5px" }}
                            label="Select Organization"
                            name="orgId"
                            rules={[{ required: true, message: 'Please Select Organization' }]}
                          >
                            <Select
                              dropdownStyle={{ zIndex: 900 }}
                              placeholder="Select Organization"
                              onChange={(value) => {
                                setAddPayload({ ...addpayload, selectedOrgId: value });
                                getDivisions(value);
                                form.setFieldsValue({ orgId: value, divisionId: null });
                              }}>
                              {orgList && orgList.map((item: any, index: any) => {
                                return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                        :
                        <div className='col-6'>
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Select Organization">
                            <Select value={localStorage?.accountName} disabled></Select>
                          </Form.Item>
                        </div>
                    }
                    {
                      (localStorage?.UserType === roles.PLATFORM_ADMIN || localStorage?.UserType === roles.DEALER_ADMIN) ?
                        <div className='col-6'>
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Division"
                            name="divisionId"
                            rules={[{ required: true, message: 'Please Select Division' }]}
                          >
                            <Select
                              dropdownStyle={{ zIndex: 900 }}
                              placeholder="Select Division">
                              {divisions?.map((item: any, index: any) => {
                                return <Select.Option key={index} value={item.divisionId}>{item.divisionName}</Select.Option>
                              })}
                            </Select>
                          </Form.Item>
                        </div> :
                        <div className='col-6'>
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Division"
                          >
                            <Select disabled value={localStorage?.divisionName}></Select>
                          </Form.Item>
                        </div>

                    }
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <Form.Item style={{ marginBottom: "5px" }}
                        label="Location Name"
                        name="locationName"
                        rules={[{ required: true, message: 'Please Enter Location Name' }]}
                      >
                        <Input placeholder="Enter Location Name" />
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item style={{ marginBottom: "5px" }}
                        label="Contact Name"
                        name="contactName"
                        rules={[{ required: true, message: 'Please Enter Contact Name' }]}
                      >
                        <Input placeholder="Enter Contact Name" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <Form.Item
                        name="locationAddress"
                        label="Location Address"
                        style={{ marginBottom: "5px" }}
                      >
                        <Input placeholder="Enter Location Address" />
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item
                        name='contactEmail'
                        label="Contact Email"
                        rules={[{ required: true, type: 'email', }]}
                        style={{ marginBottom: "5px" }}
                        required
                      >
                        <Input placeholder="Enter Email Address" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <Form.Item style={{ marginBottom: "5px" }}
                        label="Location Country"
                        name="locationCountry"
                        rules={[{ required: true, message: 'Please Select Country' }]}
                      >
                        <Select
                          showSearch
                          dropdownStyle={{ zIndex: 900 }}
                          placeholder="Select Country">
                          {CountryList?.map((item: any, index: any) => {
                            return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item
                        name="contactTelephone"
                        label="Contact Number"
                        rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                        style={{ marginBottom: "5px" }}>
                        <Input type="tel" placeholder="Enter Contact Number" maxLength={10} />
                      </Form.Item>
                    </div>

                  </div>
                  <div className="d-flex">
                    <div>
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          onClick={showAddUser}
                          style={{ background: blueColor, borderBlockColor: blueColor, color: "white", }}>
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="pl-3">
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          style={{ background: blueColor, borderBlockColor: blueColor, color: "white" }}
                          htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </ConfigProvider>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <ConfigProvider
        theme={{ token: { colorPrimary:blueColor } }}>
        <Modal
          className="update-users"
          title="Modify Location Information"
          centered
          open={isModalOpen}
          footer={null}
          okButtonProps={{ style: okButtonStyle }}
          onCancel={() => { setIsModalOpen(false) }}
        >
          <Form
            layout="vertical"
            form={updateForm}
            onFinish={handleUpdate}
            validateMessages={validateUpdateMessages}
          >
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Location Name"
              name="u_locationName"
            >
              <Input
                disabled
                placeholder="Enter Location Name"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Address"
              name={"u_address"}>
              <Input placeholder="Enter Your Address" />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <label className="form-label" htmlFor=""></label>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Location Country"
                  name="u_countryName"
                >
                  <Select
                    showSearch
                    placeholder="Select Country">
                    {CountryList?.map((item: any, index: any) => {
                      return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6">
                <label className="form-label" htmlFor=""></label>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Contact Name"
                  name='u_contactName'
                  rules={[{ required: true, message: "Please Enter Contact Name" }]}
                >
                  <Input
                    placeholder="Enter Contact Name"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label className="form-label" htmlFor=""></label>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Email Address"
                  name="email"
                  rules={[{ required: true, type: 'email' }]}
                >
                  <Input placeholder="Enter Email" required />
                </Form.Item>
              </div>
              <div className="col-6">
                <label className="form-label" htmlFor=""></label>
                <Form.Item
                  label="Contact Number"
                  name='u_contactTelephone'
                  rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                >
                  <Input
                    type="tel"
                    placeholder="Enter Contact Number"
                    maxLength={10}
                  />
                </Form.Item>
              </div>
              <div className='col-6'>
                <Form.Item style={{ marginBottom: "5px" }}
                  label="Organization"
                // name="u_orgName"
                >
                  <Select value={toUpdate.orgName} disabled
                  // style={{ marginBottom: "10px" }}
                  // placeholder="Select Organization"
                  >
                    {/* {orgList?.map((item: any, index: any) => {
                      return <Select.Option key={index} value={item.orgName}>{item.orgName}</Select.Option>
                    })} */}

                  </Select>
                </Form.Item>
              </div>
              <div className='col-6'>
                <Form.Item style={{ marginBottom: "5px" }}
                  label="Division"
                // name="u_orgName"
                >
                  <Select value={toUpdate.divisionName} disabled
                  // style={{ marginBottom: "10px" }}
                  // placeholder="Select Organization"
                  >
                    {/* {orgList?.map((item: any, index: any) => {
                      return <Select.Option key={index} value={item.orgName}>{item.orgName}</Select.Option>
                    })} */}

                  </Select>
                </Form.Item>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex justify-content-end">
                  <div>
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        onClick={() => setIsModalOpen(false)}
                        style={{ color: blueColor, }}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                  <div className="pl-3">
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        style={{ background: blueColor, borderBlockColor: blueColor, color: "white" }}
                        htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        theme={{ token: { colorPrimary:blueColor } }}>
        <Modal open={isLocUpdated} closable={false} centered title={message}
          footer={[<Button style={{ background:blueColor, color: "white" }} onClick={isLocationUpdated}>OK</Button>]}
        ></Modal>
        <Modal open={toggleDelete} okButtonProps={{ color:blueColor }} centered title={"Are You Sure Want To Deactivate This Location?"} onOk={okDeleteLoc} onCancel={() => setToggleDelete(false)} ></Modal>
        <Modal open={isLocDeleted} closable={false} centered title={Title}
          footer={[<Button style={{ background:blueColor, color: "white" }} onClick={okLocDeleted}>OK</Button>]}
        ></Modal>
        <Modal okButtonProps={{ color:blueColor }} centered title={Title} open={isOpen} onOk={() => setIsOpen(!isOpen)} onCancel={() => setIsOpen(!isOpen)} ></Modal>
      </ConfigProvider>
      <div ref={filtersRef} className="sms-report-search auto_complete  pt-2">
        <AutoComplete
          size='middle'
          style={{ borderBottomRightRadius: 0, borderTopLeftRadius: 0 }}
          options={options}
          onSelect={searchOnSelect}
          onSearch={handleSearch}
          allowClear
          onClear={onClearGetCall}
          placeholder="Search Location Name"
          className="loc-auto-complete"
          value={locQuery}
        />
        <span onClick={callLocationApi} className="search_icon"><SearchOutlined style={{ color: 'white', padding: '3.5px' }} /></span>
      </div>
      {
        locationEmpty && <div className="errorColor" style={{ paddingInline: "15px" }}>Please Enter Location Name</div>
      }
      <div id="reporting_chart5" className="container-fluid pr-0 pl-0">
        <div className="row">
          <div className="col-12 ">
            <div className="container-daily-uses mt-2">
              <div className="bgWhite">
                <div className="chartTitle py-2 d-flex">
                  <span>Locations</span>
                  <div className='ml-auto show-entries'>
                    <ShowEntries getPageSize={onSelect} />
                  </div>
                </div>
                <div style={{height:tableHeight}} ref={tabelRef} className="overflow-auto customScroll">
                  <table className="billable" style={{ width: "100%" }}>
                    <thead className='tableHeader'>
                      <tr>
                        <th>
                          <div onClick={() => sortBy('org')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Organization</span>
                            {sort.org ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('locationName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Location Name</span>
                            {sort.locationName ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('locationAddress')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Location Address</span>
                            {sort.locationAddress ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('locationCountry')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Location Country</span>
                            {sort.locationCountry ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('contactName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Contact Name</span>
                            {sort.contactName ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('email')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Contact Email</span>
                            {sort.email ? < CaretDownOutlined style={{ fontSize: '15px', color: blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('contactTelephone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Contact Number</span>
                            {sort.contactTelephone ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('deleted')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span >Deleted</span>
                            {sort.deleted ? < CaretDownOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color:blueColor, paddingRight: '0px' }} />}
                          </div>
                        </th>
                        {( localStorage.getItem('permissions')?.includes('locationedit_U') || localStorage.getItem('permissions')?.includes('locationdelete_D') ) && 
                        <th>Action</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tableData?.map((item: any, index: any) => {
                          const { locId, orgName, locationName, locationAddress, locationCountry, contactName, contactEmail, contactTelephone, status } = item;
                          return (
                            <tr key={index}>
                              <td>{orgName}</td>
                              <td>{locationName}</td>
                              <td>{locationAddress}</td>
                              <td>{locationCountry} </td>
                              <td>{contactName}</td>
                              <td>{contactEmail}</td>
                              <td>{contactTelephone}</td>
                              <td>{(status?.toString()) === "true" ? "N" : "Y"}</td>
                               {( localStorage.getItem('permissions')?.includes('locationedit_U') || localStorage.getItem('permissions')?.includes('locationdelete_D') ) && 
                              <td className="p-0">
                                {localStorage.getItem('permissions')?.includes("locationedit_U") &&
                                  <Tooltip 
                                     title={`${status ? "Update" : ""}`} 
                                     color={tooltipColor}>
                                     <EditOutlined 
                                      onClick={() => { if (!status) { } else { showUpdateLoc(item); } }}
                                      style={{ fontSize: "15px", color: 
                                              status ? blueColor : grayColor, 
                                              paddingRight: "0px", 
                                              cursor: status ? "pointer" : "auto" }} 
                                      />
                                  </Tooltip>}
                                {localStorage.getItem('permissions')?.includes("locationdelete_D") &&
                                  <Tooltip 
                                    title={`${status ? "Deactivate" : ""}`} color={tooltipColor}>
                                     <DeleteOutlined 
                                      onClick={() => { if (!status) { } else { deleteLocList(locId) } }}
                                      style={{ fontSize: "15px", 
                                             color: status ? "red" : grayColor, 
                                             paddingRight: "0px", 
                                             cursor: status ? "pointer" : "auto" }} 
                                      />
                                  </Tooltip>}
                              </td>
                             }
                            </tr>
                          );
                        })
                      }
                      {
                        tableData?.length === 0 && <tr><td colSpan={9} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div ref={paginationRef} className='text-center'>
                  <PaginationComponent
                    handlePagination={handlePagination}
                    totalLength={totalCount}
                    pageSize={pageSize}
                    current={current}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  );
};
export default LocationMaintenance;