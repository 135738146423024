import React, { useEffect, useRef, useState, FC, useLayoutEffect } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/dateNewFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, subDays, parseISO } from 'date-fns';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { resize, hideSidebar } from '../../settings/resize';
import { Button, ConfigProvider, Input, Select, Space, Modal } from "antd";


const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    batteryRxSim: string,
    serialNumber: string,
    startDate: string,
    endDate: string,
    batteryId: string,
    vehicleId: string,
    pageOffset: number,
    pageLimit: number,
    sortColumns: string,
    sortDirection: string,
    timeZone: string
}

let req = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: sevenDaysAgo,
    endDate: today,
    batteryId: "",
    vehicleId: "",
    batteryRxSim: "",
    serialNumber: "",
    pageOffset: 1,
    pageLimit: 10,
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    // refresh: number
}

const EqLog: FC<Props> = ({ globalData, action }) => {
    // const EqLog: FC<Props> = ({ globalData, action, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [selector, setSelector] = useState(false)
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, batterySim: false, log: false, startDate: false,
        endDate: false, chargeType: false, startCode: false, TermCode: false, AhrsReturned: false,
        DaysSinceLastCompEq: false, org: false, div: false, loc: false, site: false
    })
    const [totalCount, setTotalCount] = useState<number>(0)
    const [logReqBody, setlogReqBody] = useState<reqBody>(req)
    const [countReqBody, setCountReqBody] = useState<reqBody>(req)
    const [eqlog, setEqlog] = useState<string[]>([]);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(true);
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])
    const [alert, setAlert] = useState<boolean>(false);

    const selectorRef = useRef<HTMLDivElement>(null)

    const [emptyError, setemptyError] = useState<string>("");

    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        vehicleId: '',
        serialNumber: '',
        batteryRxSim: ''
    })

    const DropList = [
        { label: "Battery ID" },
        { label: "Vehicle ID" },
        { label: "Battery Rx Serial Number" }

    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")
    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const dateValue = [
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 14 Days', label: 'Last 14 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
        { value: 'Last 60 Days', label: 'Last 60 Days' },
        { value: 'Last 90 Days', label: 'Last 90 Days' },
        { value: 'Custom Date Range', label: 'Custom Date Range' }]

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 80
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        const data = {
            batteryId: '',
            vehicleId: '',
            serialNumber: '',
            batteryRxSim: ''
        }
        setSearchObject(data)
        if (inputvalue) {
            setlogReqBody({ ...logReqBody, ...data, pageOffset: 1 })
            setCountReqBody({ ...logReqBody, ...data, pageOffset: 1 })
        }
    }

    const onChangePayload = (value: any) => {
        let obj = { ...searchObject };
        if (dropdownvalue === "Battery ID") {
            obj.batteryId = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx Serial Number") {
            obj.batteryRxSim = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleId = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        }
        setemptyError("")
    }

    const callCountAndReport = (obj: any) => {
        setlogReqBody({ ...logReqBody, ...obj })
        setCountReqBody({ ...countReqBody, ...obj })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery ID':
                obj.batteryId = value;
                break;
            case 'Battery Rx Serial Number':
                obj.batteryRxSim = value;
                break;
            case 'Vehicle ID':
                obj.vehicleId = value;
                break;
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
    };

    const handleSearch = () => {
        const { batteryId, vehicleId, batteryRxSim } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery ID':
                if (!batteryId) {
                    setemptyError("Please Enter Battery ID")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx Serial Number':
                if (!batteryRxSim) {
                    setemptyError("Please Enter Battery Rx Serial Number")
                    return
                } else {
                    obj.batteryRxSim = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
    }

    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        if (date1 && date2) {
            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
        }
    }, [value])

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('eq_log_report_W')) window.history.back()
        }

        setInitial(true)
        getReportSettings();
    }, [])

    useEffect(() => {
        if (initial === false) {
            let orgid = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setInput("");
            const data = {
                batteryId: '',
                vehicleId: '',
                serialNumber: '',
                batteryRxSim: ''
            }
            setSearchObject(data)
            setlogReqBody({ ...logReqBody, ...data, pageOffset: 1, orgId: orgid, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCountReqBody({ ...countReqBody, ...data, pageOffset: 1, orgId: orgid, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    // useEffect(() => {
    //     if (refresh !== 0) {
    //         getData()
    //         getCount()
    //     }
    // }, [refresh])

    useEffect(() => {
        getData()
    }, [logReqBody])


    async function getData() {
        if (logReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = logReqBody;

        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.eqLog.getEqlogReport,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setEqlog(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getEqlogReport:", error);
            setIsShowSpinner(false);
        }
    }

    useEffect(() => {
        getCount()
    }, [countReqBody])

    async function getCount() {
        if (countReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = countReqBody

        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.eqLog.getEqlogCount,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTotalCount(response);
        } catch (error) {
            console.error("getEqlogCount:", error);
        }
    }


    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const isLeapYear = (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };

    const daysFilter = (startDate: string, endDate: string) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const isLeapYearAtStart = isLeapYear(startYear);
        const isLeapYearAtEnd = isLeapYear(endYear);
        const Difference_In_Time = end.getTime() - start.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
        if (Difference_In_Days > maxDays) {
            setModalMessage(`The maximum range allowed is 1 year.`);
            setIsModalVisible(true);
            return;
        }
        if (initial === false) {
            setlogReqBody({ ...logReqBody, startDate, endDate, pageOffset: 1 })
            setCountReqBody({ ...countReqBody, startDate, endDate, pageOffset: 1 })
            setCurrent(1)
        }
    };

    const handleModalClose = () => {
        onChange([subDays(new Date(), 6), new Date()])
        setIsModalVisible(false);
        setModalMessage('');
    };

    const onchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'EQUALIZATION_LOG',
            reportName: 'Equalization Log',
            columnSettings: selectorLabel,
            sortColumn: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection
        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const clearall = () => {
        let columnLable = selectorLabel
        let lengths = columnLable.length
        for (let i = 0; i < lengths; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        let lengths = columnLable.length
        for (let i = 0; i < lengths; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    };

    const cancelSelection = () => {
        let columnData = selectorLabel;
        let lengths = columnData.length
        for (let i = 0; i < lengths; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'EQUALIZATION_LOG'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let orgid = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setlogReqBody({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: orgid, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setCountReqBody({ ...countReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: orgid, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setInitial(false)
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const getDowFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            setIsShowSpinner(true)
            let userInfo: any = Utility.getUserInfo();
            let token = userInfo.authToken
            let payload =
            {
                orgId: logReqBody?.orgId,
                divisionId: logReqBody?.divisionId,
                locationId: logReqBody?.locationId,
                buildingId: logReqBody?.buildingId,
                startDate: logReqBody?.startDate,
                endDate: logReqBody?.endDate,
                batteryId: logReqBody?.batteryId,
                vehicleId: logReqBody?.vehicleId,
                batteryRxSim: logReqBody?.batteryRxSim,
                pageOffset: 1,
                pageLimit: totalCount + 1,
                sortColumns: logReqBody?.sortColumns,
                sortDirection: logReqBody?.sortDirection,
                timeZone: logReqBody?.timeZone
            }

            try {
                const response = await service({
                    url: constantUrl.api.eqLog.getEqlogExport + '/' + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })
                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'EqLog.' + format;
                    a.click();
                    setIsShowSpinner(false)
                }
            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        }
        else {
            setAlert(true)
        }
    }

    const formattedDate = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
        return formatDate
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "batteryId":
                if (sort.batteryId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "vehicleId":
                if (sort.vehicleId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batterySim":
                if (sort.batterySim) {
                    setlogReqBody({ ...logReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "log":
                if (sort.log) {
                    setlogReqBody({ ...logReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startDate":
                if (sort.startDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startDate", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "startDate", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startDate", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "startDate", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "endDate":
                if (sort.endDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endDate", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "endDate", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endDate", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "endDate", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeType":
                if (sort.chargeType) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "chargeTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "chargeTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startCode":
                if (sort.startCode) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "startCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "startCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "TermCode":
                if (sort.TermCode) {
                    setlogReqBody({ ...logReqBody, sortColumns: "terminationCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "terminationCodeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "terminationCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "terminationCodeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "AhrsReturned":
                if (sort.AhrsReturned) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeAh", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "chargeAh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargeAh", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "chargeAh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "DaysSinceLastCompEq":
                if (sort.DaysSinceLastCompEq) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysSinceCompEq", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysSinceCompEq", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysSinceCompEq", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysSinceCompEq", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "org":
                if (sort.org) {
                    setlogReqBody({ ...logReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "div":
                if (sort.div) {
                    setlogReqBody({ ...logReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "loc":
                if (sort.loc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "site":
                if (sort.site) {
                    setlogReqBody({ ...logReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div ref={filtersRef} className="posi_search_container">
                <div className="col-md-6 px-0 drop_search-main">
                    <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                        <div className='ant-design-select'>
                            <Select
                                value={dropdownvalue}
                                size={'middle'}
                                onChange={onChangedropdown}
                                className='drop-filter'
                                dropdownStyle={{ zIndex: 900 }}
                            >
                                {DropList && DropList.map((item: any, index: any) => {
                                    return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Space direction="vertical" size="middle" className='search-input'>
                                <Space.Compact className='posi_bmid_search_width'>
                                    <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                        onPressEnter={onPressEnter}
                                    />
                                    <Button type="primary" onClick={handleSearch}>Find</Button>
                                </Space.Compact>
                            </Space>
                            <div className="errorColor">{emptyError}</div>
                        </div>
                    </ConfigProvider>
                </div>
                <div className="column-date-filter position-relative">
                    {
                        showDateFilter && <span className="date-selector">
                            <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                        </span>
                    }
                    <button
                        onClick={selectColumn}
                        type="button"
                        className="btn btn-light selector-btn"
                    >
                        Column Selector
                    </button>
                    <span className="date-dropdown">
                        <DaysFilter
                            daysFilter={daysFilter}
                            customFilterStatusChange={setShowDateFilter}
                            dateOptions={dateValue}
                            defaultDateLabel={'Last 7 Days'}
                        />
                    </span>
                    <Modal
                        className="update-users"
                        title="Date Range Error"
                        centered
                        open={isModalVisible}
                        onOk={handleModalClose}
                        footer={[
                            <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                                OK
                            </Button>,
                        ]}
                        closable={false}
                    >
                        <p>{modalMessage}</p>
                    </Modal>
                </div>
            </div>
            <div className="main-panel pt-2 position-relative">
                <div className="ChartPanel panelShadow bg-white">
                    <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                        <div className='table_title'>EQ Log Report</div>
                        <div className='show-entries'>
                            <ShowEntries getPageSize={onSelect} />
                        </div>
                    </div>
                    <div id="table-container">
                        <div style={{ height: tableHeight + 'px' }} className="eqlog_report overflow-auto scrollbar">
                            <table className="billable width100">
                                <thead className='tableHeader'>
                                    <tr className="billable-head">
                                        {column?.[0]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[0]?.['columnName']}</span>
                                                {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th>
                                            : ""}
                                        {column?.[1]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[1]?.['columnName']}</span>
                                                {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[2]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batterySim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[2]?.['columnName']}</span>
                                                {sort.batterySim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[3]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('log')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[3]?.['columnName']}</span>
                                                {sort.log ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[4]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('startDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[4]?.['columnName']}</span>
                                                {sort.startDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[5]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('endDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[5]?.['columnName']}</span>
                                                {sort.endDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[6]?.['enabled'] ? <th><span>{column?.[6]?.['columnName']}</span></th> : ""}
                                        {column?.[7]?.['enabled'] ? <th><span>{column?.[7]?.['columnName']}</span></th> : ""}
                                        {column?.[8]?.['enabled'] ? <th><span>{column?.[8]?.['columnName']}</span></th> : ""}
                                        {column?.[9]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('AhrsReturned')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[9]?.['columnName']}</span>
                                                {sort.AhrsReturned ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[10]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('DaysSinceLastCompEq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[10]?.['columnName']}</span>
                                                {sort.DaysSinceLastCompEq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[11]?.['enabled'] ? <th>{column?.[11]?.['columnName']}</th> : ""}
                                        {column?.[12]?.['enabled'] ? <th>{column?.[12]?.['columnName']}</th> : ""}
                                        {column?.[13]?.['enabled'] ? <th>{column?.[13]?.['columnName']}</th> : ""}
                                        {column?.[14]?.['enabled'] ? <th>{column?.[14]?.['columnName']}</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {eqlog?.map((item: any, index: number) => {
                                        return <tr key={`eq_log_${index - 1}`}>
                                            {column?.[0]?.['enabled'] ? <td>{item?.batteryId}</td> : ""}
                                            {column?.[1]?.['enabled'] ? <td>{item?.vehicleId}</td> : ""}
                                            {column?.[2]?.['enabled'] ? <td>{item?.batteryRxSim}</td> : ""}
                                            {column?.[3]?.['enabled'] ? <td>{item?.logNumber}</td> : ""}
                                            {column?.[4]?.['enabled'] ? <td>{item.formatStartDate}</td> : ""}
                                            {column?.[5]?.['enabled'] ? <td>{item.formatEndDate}</td> : ""}
                                            {column?.[6]?.['enabled'] ? <td>{item?.chargeTypeDesc}</td> : ""}
                                            {column?.[7]?.['enabled'] ? <td>{item?.startCodeDesc}</td> : ""}
                                            {column?.[8]?.['enabled'] ? <td>{item?.terminationCodeDesc}</td> : ""}
                                            {column?.[9]?.['enabled'] ? <td>{item?.chargeAh}</td> : ""}
                                            {column?.[10]?.['enabled'] ? <td>{item.daysSinceCompEq}</td> : ""}
                                            {column?.[11]?.['enabled'] ? <td>{item?.orgName}</td> : ""}
                                            {column?.[12]?.['enabled'] ? <td>{item?.divisionName}</td> : ""}
                                            {column?.[13]?.['enabled'] ? <td>{item?.locationName}</td> : ""}
                                            {column?.[14]?.['enabled'] ? <td>{item?.buildingName}</td> : ""}
                                        </tr>
                                    })}
                                    {
                                        eqlog?.length === 0 && <tr><td colSpan={column.filter((item: any) => item?.['enabled'] === true)?.length} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div ref={paginationRef}>
                            <PaginationComponent
                                handlePagination={handlePagination}
                                totalLength={totalCount}
                                pageSize={pageSize}
                                current={current}
                            />
                        </div>
                    </div>

                </div>
                <div ref={selectorRef} className="column-selector selector ">
                    <div className="column-title">Column Selector</div>
                    <div className="slector-body">
                        <label>
                            <input
                                checked={clearAll}
                                value="clearAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={clearall}
                            />
                            <span></span>
                        </label>
                        <p>Clear All</p>

                        <label>
                            <input
                                checked={selectAll}
                                value="selectAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={selectall}
                            />
                            <span></span>
                        </label>
                        <p>Select All</p>
                    </div>
                    <div style={{ height: tableHeight - 130 + 'px' }} className="selector-body-height scrollbar">
                        {selectorLabel?.map((item: any, index: any) => {
                            return (
                                <div key={index + 2} className="slector-body">
                                    <label>
                                        <input
                                            key={index}
                                            name={index}
                                            onChange={onchange}
                                            checked={item['enabled']}
                                            value={item.value}
                                            type="checkbox"
                                            aria-label="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                    <p>{item['columnName']}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="m-flex">
                        <button onClick={cancelSelection} className="cancel">
                            Cancel
                        </button>
                        <button onClick={applyChanges} className="apply-changes mr-3">
                            Apply Changes
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    )
}
export default EqLog