import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { ReactFC } from '../fusionChart/fusionChartsConfig';
import LoadingSpinner from '../spinner/loading-spinner';
import BatteryDetails from '../reusableComponent/batteryDetalis';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/dateNewFilter';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng } from 'html-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import getBatterySummary from '../../api-call/battery-summary';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { resize, hideSidebar } from '../../settings/resize';
import SelectFilter from '../reusableComponent/selectFilter';
import { Modal, Button } from 'antd';
//ReactFC.fcRoot(FusionCharts, Charts, ZoomLine, FusionTheme);

const startOfCurrentMonth: any = format(subDays(new Date(), 13), 'dd-MMM-yyyy')
const today: any = format(new Date(), 'dd-MMM-yyyy');

type reqType = {
  startDate: string,
  endDate: string,
  orgId: number,
  batteryId: string,
  vehicleId: string,
  divisionId: number,
  locationId: number,
  buildingId: number,
  serialNumber: string
}
const req = {
  "startDate": startOfCurrentMonth,
  "endDate": today,
  "orgId": 0,
  "batteryId": "",
  "vehicleId": "",
  "divisionId": -1,
  "locationId": -1,
  "buildingId": -1,
  "serialNumber": ""
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string,
  // refresh: number
}


const BatterySocReport: FC<Props> = ({ globalData, action }) => {
  // const BatterySocReport: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState({ startDate: startOfCurrentMonth, endDate: today, orgId: 0, batteryId: "", vehicleId: "", divisionId: -1, locationId: -1, buildingId: -1, serialNumber: "" })
  const [serialNoReqBody, setSerialNoReqBody] = useState({ startDate: startOfCurrentMonth, endDate: today, orgId: 0, divisionId: -1, locationId: -1, buildingId: -1, batteryId: "", vehicleId: "" })
  const [serialNo, setSerialNo] = useState<any[]>([])
  const [filterData, setFilterData] = useState<any[]>([]);
  const [dateLabel, setDateLabel] = useState<{}[]>([])
  const [chartType, setChartType] = useState<string>("zoomline");
  const [avgVolt, setAvgVolt] = useState<number | string>();
  const ref = useRef<HTMLDivElement>(null)
  const [summaryBatteryReq, setSummaryBatteryReq] = useState<reqType>(req)
  const [summaryBattery, setSummaryBattery] = useState<any>([])
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showSummary, setShowSummary] = useState<boolean>(false)
  const [initial, setInitial] = useState<boolean>(true);
  const [points, setPoints] = useState<string>("50");
  const [value2, setValue] = useState<[Date, Date] | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const dateValue = [
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 14 Days', label: 'Last 14 Days' },
    { value: 'Custom Date Range', label: 'Custom Date Range' }]

  // useEffect(() => {
  //   if (refresh === 0) return
  //   let globalData: any = sessionStorage.getItem('globalData')
  //   let parseData = JSON.parse(globalData)
  //   if (parseData.batteryId || parseData.vehicleId) setSummaryBatteryReq({ ...summaryBatteryReq })
  //   setReqBody({ ...reqBody })
  //   setSerialNoReqBody({ ...serialNoReqBody })
  // }, [refresh])

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? startOfCurrentMonth, date2 ?? today, "Custom Date Range");
    }
  }, [value])

  useEffect(() => {
    batterySummary()
  }, [summaryBatteryReq])

  const batterySummary = () => {

    if (summaryBatteryReq.orgId === 0 || (!summaryBatteryReq?.batteryId && !summaryBatteryReq?.vehicleId)) return
    getBatterySummary.batterySummary(summaryBatteryReq)
      .then((response) => {
        setSummaryBattery(response)
        setShowSummary(true)
      }).catch((error) => {
        console.log('batterySummary:', error)
      })
  }

  useEffect(() => {
    if (reqBody.orgId === 0 || reqBody.batteryId === "") {
      setDateLabel([{ label: "" }])
      setFilterData([{ value: "0" }])
    } else {
      let userInfo: any = Utility.getUserInfo();
      var token = userInfo.authToken;
      setIsShowSpinner(true);
      (async () => {
        try {
          const response = await service({
            url: constantUrl.api.socreport.socreport,
            method: "POST",
            headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
            data: reqBody
          })
          if (response.length) {
            frameChart(response)
          }
          else {
            setDateLabel([{ label: "" }])
            setFilterData([{ value: "0" }])
          }
          setIsShowSpinner(false)
        } catch (error) {
          console.error("dailyUsesError:", error)
          setIsShowSpinner(false)
        }
      })();
    }
  }, [reqBody])

  const formatDate = (date: any) => {
    let date1 = new Date(date);
    var totalMinutes = date1.getTimezoneOffset();
    var hours = 0;
    if (totalMinutes > 0) {
      hours = Math.floor(totalMinutes / 60);
    } else {
      hours = Math.ceil(totalMinutes / 60);
    }
    var minutes = totalMinutes % 60;
    date1.setHours(date1.getHours() + hours);
    date1.setMinutes(date1.getMinutes() + minutes);

    return format(date1, 'MM/dd/yyyy')
  }

  const frameChart = (data: any[]) => {
    if (!data.length) return
    // let labels = data.flatMap((item: any) => { return [{ label: formatDate(item.startDate) }, { label: formatDate(item.endDate) }] })
    let record = data.flatMap((item: any) => { return [{ value: item.startSoc, color: '#e8e368' }, { value: item.endSoc, color: '#5083b7', dashed: 1 }] })
    // let response = data.flatMap((item: any) => { return [formatDate(item.startDate), formatDate(item.endDate)] });
    let response = data.flatMap((item: any) => { return [item.mmddStart, item.mmddEnd] });
    let labels: any = [];
    let values: string[] = [];

    for (let index = 0; index < response.length; index++) {
      if (values.includes(response[index])) {
        labels.push({ label: "" });
      } else {
        values.push(response[index]);
        labels.push({ label: response[index] });
      }
    }
    setFilterData(record)
    setDateLabel(labels)
    setAvgVolt(Math.round(data[0]['avgSoc']))

  }

  const chartConfigs_line = {
    type: 'zoomline',
    dataFormat: 'json',
    height: '98%',
    width: '100%',
    dataSource: {
      "chart": {
        yaxisname: "SOC %",
        yaxisminvalue: "0",
        yaxismaxvalue: "110",
        xAxisName: "DATE",
        xAxisNameFontColor: "#4f82b6",
        xAxisNameFontBold: "1",
        yAxisNameFontColor: "#4f82b6",
        yAxisNameFontBold: "1",
        numDivLines: "10",
        rotatelabels: "1",
        lineThickness: "3",
        setadaptiveymin: "1",
        // showToolTip: '1',
        "useCrossLine": "0",
        // drawAnchors: '1',
        labelFontSize: "12",
        labelDisplay: "auto",
        chartTopMargin: "70",
        useEllipsesWhenOverflow: "1",
        flatScrollBars: "1",
        scrollheight: "10",
        // numVisiblePlot: points,
        isTrendZone: "1",
        plotToolText: "<div class='__head'>SOC %</div><div class='__body'>$dataValue</div><div class='__footer'>$label</div>",
        toolTipBgAlpha: "100",
        // labelStep: "0",
        connectNullData: "0",
        divlinedashed: "1",
        divlinedashlen: "4",
        divlinedashgap: "2",
        divlinealpha: "60",
        divlinecolor: "#ACACAC",
        // "forceAxisLimits": "1",
        "pixelsPerPoint": "0",
        /* "pixelsPerLabel": "30", */
        // "compactdatamode": "1",
        /* "dataseparator": "|", */
        // "labelHeight": "30",
        "theme": "fusion"
      },
      trendlines: [{
        line: [{
          startvalue: avgVolt ? avgVolt : 0,
          color: "#e20a15",
          valueOnRight: "1",
          displayvalue: `<b>${avgVolt ? avgVolt : 0}% <br>Low Threshold</b>`,
          thickness: "3",
          dashed: "1",
          dashLen: "4",
          dashGap: "2",
          labelFontSize: "3"
        }]
      }],
      "categories": [{
        "category": dateLabel.length ? dateLabel : [{ label: "" }]
        //dateLabel
        //[{label: "2022-08-01"}]
      }],
      "dataset": [{
        "data": filterData.length ? filterData : [{ value: "0" }]
        // filterData 
        //[{value: "16"}]
      }]
    }
  }

  useEffect(() => {
    setInitial(true);
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;

    (data?.batteryId === "" && data?.vehicleId === "") ? setShowSummary(false) : setShowSummary(true)

    setSummaryBatteryReq({ ...summaryBatteryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId });
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId });
    setSerialNoReqBody({ ...serialNoReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false);
  }, [])

  useEffect(() => {
    if (initial === false) {
      let data: any = sessionStorage.getItem('globalData');
      data = data && JSON.parse(data);

      (data?.batteryId === "" && data?.vehicleId === "") ? setShowSummary(false) : setShowSummary(true)
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

      setSummaryBatteryReq({ ...summaryBatteryReq, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId });
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId });
      setSerialNoReqBody({ ...serialNoReqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const daysFilter = (startDate: any, endDate: string, value: string) => {
    var Difference_In_Time = new Date(endDate).getTime() - new Date(startDate).getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 59) {
      setModalMessage('The maximum range allowed is 60 days.');
      setIsModalVisible(true);
      return;
    }

    setReqBody({ ...reqBody, startDate, endDate })
    setSummaryBatteryReq({ ...summaryBatteryReq, startDate, endDate })
    setSerialNoReqBody({ ...serialNoReqBody, startDate, endDate })
  };

  const handleModalClose = () => {
    onChange([subDays(new Date(), 13), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  useEffect(() => {
    if (action !== "") {
      onButtonClick(action)
    }
  }, [action])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) return
    setIsShowSpinner(true);
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'State_Of_Charge.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', 5, 20, 200, 100);
          pdf.save("State_Of_Charge.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }

  }, [ref])

  useEffect(() => {
    getSerialNo()
  }, [serialNoReqBody])

  const getSerialNo = async () => {
    if (!serialNoReqBody.orgId) return
    const userInfo: any = Utility.getUserInfo();
    const token = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.dailyPlugInReport.serialNumbers,
        method: "POST",
        headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
        data: serialNoReqBody
      })
      setSerialNo(response)
    } catch (error) {
      console.error("serialNo:", error)
    }
  }

  const filterByserialNumber = (value: string) => {
    setReqBody({ ...reqBody, serialNumber: value })
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div>
        {showSummary && <BatteryDetails batteryData={summaryBattery[0]} />}

        <div className="container-battery-voltage" ref={ref}>
          <div className="chartTitle graph_label_head p-2 text-center d-flex align-items-center">
            <SelectFilter
              placeholder={"Serial Number"}
              dropDownList={serialNo}
              sizeType={"small"}
              filterById={filterByserialNumber}
              width={200}
              allowClear={false}
            />
            <span className='mr-auto marginLeft150px marginLeft250px' style={{ marginLeft: 330 }}>Battery State Of Charge</span>
          </div>
          <div className="chartTitle graph_label_mobile_head p-2 text-center d-flex align-items-center">
            <span>Battery State Of Charge</span>
          </div>
          <div className='serail_position graph_label_mobile_head'>
            <SelectFilter
              placeholder={"Serial Number"}
              dropDownList={serialNo}
              sizeType={"small"}
              filterById={filterByserialNumber}
              width={200}
              allowClear={false}
            />
          </div>
          <span className="export2 graph_label_mobile_head">
            {
              showDateFilter && <span>
                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
              </span>
            }
            <SelectDays
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 14 Days'}
            />
          </span>
          <span className="export2 graph_label_head">
            {
              showDateFilter && <span>
                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
              </span>
            }
            <SelectDays
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 14 Days'}
            />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
          <div className='chart-tooltip'>
            <div className='battey-voltage-chart' id="battey-voltage-chart">
              <ReactFC {...chartConfigs_line} />
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default BatterySocReport;