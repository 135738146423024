import React, { useState, useEffect } from 'react'
import { Input, Switch, Button, Checkbox, Modal } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import { resize } from '../../settings/resize';

const { TextArea } = Input;

type statesType = {
  enabledAlerts: string,

  basicSOCAlertsActive: boolean,
  basicSosAlerts: any[],
  enableBatteryLowVoltage: boolean,
  enableBMIDPowerInterruptionIII: boolean,
  enableDaysSinceLastChargeExceeded: boolean,
  enableEQMinutesExceedWeeklyLimit: boolean,

  criticalSOSAlertsActive: boolean,
  criticalSosAlerts: any[],
  enableBatteryHighTemperatureLimit: boolean,
  enableBateryHighVoltage: boolean,
  enableBatteryLowSOC: boolean,
  enableBatteryLowWater: boolean,
  enableEQTimeExceedHrs: boolean,

  specifiedSOSAlertsActive: boolean,
  selectedSosAlerts: any[],
  enableBatteryLowTemperatureLimit: boolean,
  enableCurrentExceedsLimit: boolean,
  enableCurrentSensorFailure: boolean,
  enableDaysSinceLastCompleteEQExceed: boolean,
  enableEQCurrentOutOfLimit: boolean,
  // enableMissedPlugIn: boolean,  
  enablePackImbalanceExceeded: boolean,
  enableTemperatureSensorFailure: boolean,
  enableZeroCurrentOutOfSpec: boolean,
  enableZeroCurrentPointChanged: boolean,
  chargerAlertsEnabled: string,
  alertEmail: string,
  timeZone: string
}

const state = {
  "enabledAlerts": 'N',

  "basicSOCAlertsActive": false,
  "basicSosAlerts": [],
  "enableBatteryLowVoltage": false,
  "enableBMIDPowerInterruptionIII": false,
  "enableDaysSinceLastChargeExceeded": false,
  "enableEQMinutesExceedWeeklyLimit": false,

  "criticalSOSAlertsActive": false,
  "criticalSosAlerts": [],
  "enableBatteryHighTemperatureLimit": false,
  "enableBateryHighVoltage": false,
  "enableBatteryLowSOC": false,
  "enableBatteryLowWater": false,
  "enableEQTimeExceedHrs": false,

  "specifiedSOSAlertsActive": false,
  "selectedSosAlerts": [],
  "enableBatteryLowTemperatureLimit": false,
  "enableCurrentExceedsLimit": false,
  "enableCurrentSensorFailure": false,
  "enableDaysSinceLastCompleteEQExceed": false,
  "enableEQCurrentOutOfLimit": false,
  // "enableMissedPlugIn": false,  
  "enablePackImbalanceExceeded": false,
  "enableTemperatureSensorFailure": false,
  "enableZeroCurrentOutOfSpec": false,
  "enableZeroCurrentPointChanged": false,

  "chargerAlertsEnabled": 'N',
  "alertEmail": "",
  "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone
}

const AlertSettings = () => {
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [states, setStates] = useState<statesType>(state)
  const [validEmail, setValidEmail] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [checkBoxStatus4, setCheckBoxStatus4] = useState<boolean>(true)
  const [basicSosValue, setBasicSosValue] = useState<any>([])
  const [criticalSosValue, setCriticalSosValue] = useState<any>([])
  const [specifiedSosValue, setSpecifiedSosValue] = useState<any>([])

  const [basicSOSFaultcode, setBasicSOSFaultcode] = useState<any[]>([])
  const [criticalSOSFaultcode, setCriticalSOSFaultcode] = useState<any[]>([])
  const [specifiedSOSFaultcode, setSpecifiedSOSFaultcode] = useState<any[]>([])

  const [basicSOSFaultcodeAll, setBasicSOSFaultcodeAll] = useState<any[]>([])
  const [criticalSOSFaultcodeAll, setCriticalSOSFaultcodeAll] = useState<any[]>([])
  const [specifiedSOSFaultcodeAll, setSpecifiedSOSFaultcodeAll] = useState<any[]>([])
  const [message, setMessage] = useState<string>("")

  let invalidError = "Please Enter Valid Email ID"



  useEffect(() => {
    resize();
    getAccountSettings()
  }, [])

  const basicSosFaults = async (getvalues: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.accountProfile.basicSosFaultAlerts,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      let basicSOSFaultcode: any[] = []

      for (const data of response) { basicSOSFaultcode.push(data?.faultCode) }
      setBasicSOSFaultcodeAll(basicSOSFaultcode)


      let selectTrueFaultcode: any[] = []
      let basciAlertCheckboxValue = ["enableBatteryLowVoltage", "enableBMIDPowerInterruptionIII", "enableDaysSinceLastChargeExceeded", "enableEQMinutesExceedWeeklyLimit"]

      let basicSosValue: any[] = []
      for (let i = 0; i < response.length; i++) {
        let dataValue = response[i]
        dataValue['name'] = basciAlertCheckboxValue[i]
        dataValue['value'] = getvalues[i]
        basicSosValue.push(dataValue)
        getvalues[i] && selectTrueFaultcode.push(response[i]['faultCode'])
      }
      setBasicSOSFaultcode(selectTrueFaultcode)
      //setBasicSosValue(response)
      setBasicSosValue(basicSosValue)
    } catch (error) {
      console.log(error)
    }
  };

  const criticalSosAlert = async (getvalues: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.accountProfile.criticalSosAlerts,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      let criticalSOSFaultcode: any[] = []
      for (const data of response) criticalSOSFaultcode.push(data?.faultCode)
      setCriticalSOSFaultcodeAll(criticalSOSFaultcode)


      let selectTrueFaultcode: any[] = []
      let criticalSosAlertCheckboxValue = ["enableBatteryHighTemperatureLimit", "enableBateryHighVoltage", "enableBatteryLowSOC", "enableBatteryLowWater", "enableEQTimeExceedHrs"]
      let criticalSosValue: any[] = []
      for (let i = 0; i < response.length; i++) {
        let dataValue = response[i]
        dataValue['name'] = criticalSosAlertCheckboxValue[i]
        dataValue['value'] = getvalues[i]
        criticalSosValue.push(dataValue)
        getvalues[i] && selectTrueFaultcode.push(response[i]['faultCode'])
      }
      setCriticalSOSFaultcode(selectTrueFaultcode)
      setCriticalSosValue(criticalSosValue)
    } catch (error) {
      console.log(error)
    }
  };
  const specifiedSos = async (getvalues: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.accountProfile.specifiedSos,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      let specifiedSOSFaultcode: any[] = []
      for (const data of response) specifiedSOSFaultcode.push(data?.faultCode)
      setSpecifiedSOSFaultcodeAll(specifiedSOSFaultcode)

      let selectTrueFaultcode: any[] = []
      let specifiedSOSSValue: any[] = []
      let specifiedSOSCheckboxValue = ["enableBatteryLowTemperatureLimit", "enableCurrentExceedsLimit", "enableCurrentSensorFailure",
        "enableDaysSinceLastCompleteEQExceed", "enableEQCurrentOutOfLimit", "enablePackImbalanceExceeded",
        "enableTemperatureSensorFailure", "enableZeroCurrentOutOfSpec", "enableZeroCurrentPointChanged"]
      for (let i = 0; i < response.length; i++) {
        let dataValue = response[i]
        dataValue['name'] = specifiedSOSCheckboxValue[i]
        dataValue['value'] = getvalues[i]
        specifiedSOSSValue.push(dataValue)
        getvalues[i] && selectTrueFaultcode.push(response[i]['faultCode'])
      }
      setSpecifiedSOSFaultcode(selectTrueFaultcode)
      setSpecifiedSosValue(specifiedSOSSValue)

    } catch (error) {
      console.log(error)
    }
  };

  const getAccountSettings = async () => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.accountProfile.getAlert,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      const { enabledAlerts, chargerAlertsEnabled, basicSOCAlertsActive, criticalSOSAlertsActive,
        specifiedSOSAlertsActive, alertEmail,
        enableBatteryLowVoltage, enableBMIDPowerInterruptionIII, enableDaysSinceLastChargeExceeded, enableEQMinutesExceedWeeklyLimit,
        enableBatteryHighTemperatureLimit, enableBateryHighVoltage, enableBatteryLowSOC, enableBatteryLowWater, enableEQTimeExceedHrs,
        enableBatteryLowTemperatureLimit, enableCurrentExceedsLimit, enableCurrentSensorFailure, enableDaysSinceLastCompleteEQExceed, enableEQCurrentOutOfLimit,
        enablePackImbalanceExceeded, enableTemperatureSensorFailure, enableZeroCurrentOutOfSpec, enableZeroCurrentPointChanged
      } = response
      setStates({
        ...state,
        enabledAlerts,
        basicSOCAlertsActive,
        criticalSOSAlertsActive,
        specifiedSOSAlertsActive,
        chargerAlertsEnabled,
        alertEmail
      })

      basicSosFaults([enableBatteryLowVoltage, enableBMIDPowerInterruptionIII, enableDaysSinceLastChargeExceeded, enableEQMinutesExceedWeeklyLimit])
      criticalSosAlert([enableBatteryHighTemperatureLimit, enableBateryHighVoltage, enableBatteryLowSOC, enableBatteryLowWater, enableEQTimeExceedHrs])
      specifiedSos([enableBatteryLowTemperatureLimit, enableCurrentExceedsLimit, enableCurrentSensorFailure, enableDaysSinceLastCompleteEQExceed, enableEQCurrentOutOfLimit,
        enablePackImbalanceExceeded, enableTemperatureSensorFailure, enableZeroCurrentOutOfSpec, enableZeroCurrentPointChanged])
    } catch (error) {
      console.log(error)
      basicSosFaults([])
      criticalSosAlert([])
      specifiedSos([])
    } finally {
      setIsShowSpinner(false)

    }
  };

  const applyChanges = async () => {
    const email = states.alertEmail ? states.alertEmail.trim() : '';
    const listOfEmail = email.split(',')
 
    if(!email){
       setValidEmail('Please Enter Email Id')
       return
    }else{
     for (let email of listOfEmail){
             if(validateEmail(email)){
               setValidEmail("")
             }else{
               setValidEmail(invalidError)
               return
             }
     }
   }
   
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    let payload = {
      ...states,
      basicSosAlerts: basicSOSFaultcode,
      enableBatteryLowVoltage: basicSosValue[0]['value'],
      enableBMIDPowerInterruptionIII: basicSosValue[1]['value'],
      enableDaysSinceLastChargeExceeded: basicSosValue[2]['value'],
      enableEQMinutesExceedWeeklyLimit: basicSosValue[3]['value'],

      criticalSosAlerts: criticalSOSFaultcode,
      enableBatteryHighTemperatureLimit: criticalSosValue[0]['value'],
      enableBateryHighVoltage: criticalSosValue[1]['value'],
      enableBatteryLowSOC: criticalSosValue[2]['value'],
      enableBatteryLowWater: criticalSosValue[3]['value'],
      enableEQTimeExceedHrs: criticalSosValue[4]['value'],

      selectedSosAlerts: specifiedSOSFaultcode,
      enableBatteryLowTemperatureLimit: specifiedSosValue[0]['value'],
      enableCurrentExceedsLimit: specifiedSosValue[1]['value'],
      enableCurrentSensorFailure: specifiedSosValue[2]['value'],
      enableDaysSinceLastCompleteEQExceed: specifiedSosValue[3]['value'],
      enableEQCurrentOutOfLimit: specifiedSosValue[4]['value'],
      enablePackImbalanceExceeded: specifiedSosValue[5]['value'],
      enableTemperatureSensorFailure: specifiedSosValue[6]['value'],
      enableZeroCurrentOutOfSpec: specifiedSosValue[7]['value'],
      enableZeroCurrentPointChanged: specifiedSosValue[8]['value'],
    }
    setIsShowSpinner(true)

    try {
      const response = await service({
        url: constantUrl.api.accountProfile.putAlert,
        method: "PUT",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setOpen(true)
      setMessage(response.data['message'])
    } catch (error) {
      console.log(error)
    } finally {
      setIsShowSpinner(false)
    }
  };

  const emailOnchange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // if (validateEmail(e.target.value) || !e.target.value) {
    //   setValidEmail("")
    // } else {
    //   setValidEmail(invalidError)
    // }
    setValidEmail("")
    setStates({ ...states, alertEmail: e.target.value })

  };

  const validateEmail = (email: any) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email)
  };

  const switchBasicSocAlert = (checked: boolean) => {
    checked ? setBasicSOSFaultcode(basicSOSFaultcodeAll) : setBasicSOSFaultcode([])

    setStates({ ...states, basicSOCAlertsActive: checked, })

    let basicSosValues = basicSosValue
    for (let i = 0; i < basicSosValues.length; i++) {
      basicSosValues[i]['value'] = checked
    }
    setBasicSosValue(basicSosValues)
  };

  const criticalSosAlertOnchange = (checked: boolean) => {
    checked ? setCriticalSOSFaultcode(criticalSOSFaultcodeAll) : setCriticalSOSFaultcode([])
    setStates({ ...states, criticalSOSAlertsActive: checked, })

    let criticalSOSValues = criticalSosValue
    for (let i = 0; i < criticalSOSValues.length; i++) {
      criticalSOSValues[i]['value'] = checked
    }
    setCriticalSosValue(criticalSOSValues)

  };

  const switchSpecifiedSosAlert = (checked: boolean) => {
    checked ? setSpecifiedSOSFaultcode(specifiedSOSFaultcodeAll) : setSpecifiedSOSFaultcode([])
    setStates({ ...states, specifiedSOSAlertsActive: checked })

    let specifiedSOSValues = specifiedSosValue
    for (let i = 0; i < specifiedSOSValues.length; i++) {
      specifiedSOSValues[i]['value'] = checked
    }
    setSpecifiedSosValue(specifiedSOSValues)
  };

  const enabaleFaultEmailAlerts = (checked: boolean) => {
    setCheckBoxStatus4(!checked)
    if (checked) {
      setStates({ ...states, chargerAlertsEnabled: 'Y' })
    } else {
      setStates({
        ...states, chargerAlertsEnabled: 'N',
        //  alertEmail:""
      })
      //setValidEmail("")
    }
  }

  const basicSOScheckbox = (event: CheckboxChangeEvent) => {
    const { checked, name, id } = event.target
    const index = basicSosValue.findIndex((item: any) => item.name === name)
    if (index !== -1) {
      const updatedBasicSosValue = [...basicSosValue]; // Copy the original array
      updatedBasicSosValue[index] = {
        ...updatedBasicSosValue[index], // Copy the object at the specific index
        value: checked,// Update the value field
      };
      setBasicSosValue(updatedBasicSosValue); // Update the state with the modified array
    };

    let basicSOSFaultcodeS = [...basicSOSFaultcode]
    const indexToremove = basicSOSFaultcodeS.findIndex((item: any) => item === id)
    if (checked) {
      indexToremove == -1 && basicSOSFaultcodeS.push(id)
    } else {
      indexToremove !== -1 && basicSOSFaultcodeS.splice(indexToremove, 1);
    }
    setBasicSOSFaultcode(basicSOSFaultcodeS)
  };

  const cirticalSOScheckbox = (event: CheckboxChangeEvent) => {
    const { checked, name, id } = event.target
    const index = criticalSosValue.findIndex((item: any) => item.name === name)
    if (index !== -1) {
      const updatecriticalSosValue = [...criticalSosValue]; // Copy the original array
      updatecriticalSosValue[index] = {
        ...updatecriticalSosValue[index], // Copy the object at the specific index
        value: checked,// Update the value field
      };
      setCriticalSosValue(updatecriticalSosValue); // Update the state with the modified array
    };

    let criticalSOSFaultcodeS = [...criticalSOSFaultcode]
    const indexToremove = criticalSOSFaultcodeS.findIndex((item: any) => item === id)
    if (checked) {
      indexToremove == -1 && criticalSOSFaultcodeS.push(id)
    } else {
      indexToremove !== -1 && criticalSOSFaultcodeS.splice(indexToremove, 1);
    }
    setCriticalSOSFaultcode(criticalSOSFaultcodeS)

  };

  const specifiedSOScheckbox = (event: CheckboxChangeEvent) => {
    const { checked, name, id } = event.target
    const index = specifiedSosValue.findIndex((item: any) => item.name === name)
    if (index !== -1) {
      const updateSpecifiedSosValue = [...specifiedSosValue]; // Copy the original array
      updateSpecifiedSosValue[index] = {
        ...updateSpecifiedSosValue[index], // Copy the object at the specific index
        value: checked,// Update the value field
      };
      setSpecifiedSosValue(updateSpecifiedSosValue); // Update the state with the modified array
    };

    let specifiedSOSFaultcodes = [...specifiedSOSFaultcode]
    const indexToremove = specifiedSOSFaultcodes.findIndex((item: any) => item === id)
    if (checked) {
      indexToremove == -1 && specifiedSOSFaultcodes.push(id)
    } else {
      indexToremove !== -1 && specifiedSOSFaultcodes.splice(indexToremove, 1);
    }
    setSpecifiedSOSFaultcode(specifiedSOSFaultcodes)
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 padd2'>
            <div className='enable_email'>
              <div className='font1'>Enable Automated Email Alerts</div>
              <Switch checked={states.enabledAlerts === 'Y' ? true : false}
                onChange={(checked: boolean) => setStates({ ...states, enabledAlerts: checked ? 'Y' : 'N' })}
              />
            </div>
            <div>
              <div className='font1 py-2'>Battery Fault Alert Categories</div>
              <div className='alert-ml'>
                <div className='enable_email'>
                  <div className='font3'>Basic SOS Alert-may not require customer service</div>
                  <Switch checked={states.basicSOCAlertsActive}
                    onChange={switchBasicSocAlert}
                  />
                </div>
                <div className='ml2'>
                  {basicSosValue.map((item: any, index: any) => {
                    return (
                      <div key={'index1' + index}>
                        <Checkbox
                          className='font3'
                          name={item?.['name']}
                          checked={item?.['value']}
                          id={item?.faultCode}
                          onChange={basicSOScheckbox}
                        >
                          {item?.['faultDescription']}
                        </Checkbox>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='alert-ml'>
                <div className='enable_email'>
                  <div className='font3'>Critical SOS Alerts-customer service recommended</div>
                  <Switch checked={states.criticalSOSAlertsActive}
                    onChange={criticalSosAlertOnchange}
                  />
                </div>
                <div className='ml2'>
                  {criticalSosValue.map((item: any, index: any) => {
                    return (
                      <div key={'index2' + index}>
                        <Checkbox
                          className='font3'
                          name={item?.['name']}
                          checked={item?.['value']}
                          id={item?.faultCode}
                          onChange={cirticalSOScheckbox}
                        >
                          {item?.['faultDescription']}
                        </Checkbox>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                <div>
                </div>
              </div>
            </div>
            {/* battery fault alert type */}
            <div>
              <div className='font1 py-2'>Battery Fault Alert Types</div>
              <div className='alert-ml'>
                <div className='enable_email'>
                  <div className='font3'>Specified SOS Type Alerts</div>
                  <Switch checked={states.specifiedSOSAlertsActive}
                    onChange={switchSpecifiedSosAlert}
                  />
                </div>
                <div className='ml2'>
                  {specifiedSosValue.map((item: any, index: any) => {
                    return (
                      <div key={'index3' + index}>
                        <Checkbox
                          className='font3'
                          name={item?.['name']}
                          checked={item?.['value']}
                          id={item?.faultCode}
                          onChange={specifiedSOScheckbox}
                        >
                          {item?.['faultDescription']}
                        </Checkbox>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                <div>
                </div>
              </div>
            </div>
            <div className='enable_email py-3'>
              <div className='font1'>Enable Charge Fault Email Alerts</div>
              <Switch checked={states.chargerAlertsEnabled === 'Y' ? true : false}
                onChange={enabaleFaultEmailAlerts}
              />
            </div>
            {/* Email Address For Notification */}
            <div className='send_email_notification'>
              <div className='font1'>Email Address For Notification</div>
              <div className='font3'>
                <div className='font3'>
                  Use commas to seperate email addresses.
                  In Order to receive SMS,you can type your 10 digits phone number @ your Mobile Provider SMS
                  gateway domain i.e for T-Mobile number:
                  6261636465@tmomail.net.(You can find your mobile provider SMS gateway online and/or in http://en.wikipedia.org/wiki/SMS_gateway)
                </div>
                <div className='col-lg-4 col-md-6 px-0 py-3'>
                  <TextArea placeholder="Email@Webasto.com"
                    // disabled={checkBoxStatus4}
                    value={states.alertEmail}
                    onChange={emailOnchange}
                    allowClear />
                  {validEmail ? <span className='errorColor'>{validEmail}</span> : ""}
                </div>
              </div>
            </div>
            <div className='msgFlex pb-2'>
              <Button
                onClick={applyChanges}
                style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                APPLY CHANGES
              </Button>
              <span className='font1'>{message}</span>
            </div>
          </div>
        </div>
        <Modal open={open} closable={false} centered title="Settings updated successfully"
          footer={[
            <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setOpen(false)}>OK</Button>
          ]}>
        </Modal>
      </div>
    </>

  )
}

export default AlertSettings;