import React, { FC, useEffect, useState, useLayoutEffect, useDeferredValue } from 'react';
import { Link } from 'react-router-dom';
import { Input, Pagination } from 'antd';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import Usa from 'fusioncharts/maps/fusioncharts.usa';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// import CountryChart from '../fusionChart/countryChart';
import Charts from 'fusioncharts/fusioncharts.charts';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, startOfWeek } from 'date-fns';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import TaskSummary from '../reusableComponent/TaskSummary';
import OperationStatus from '../reusableComponent/operations-status';
import Summary from '../reusableComponent/summary';
import Backdrop from '@mui/material/Backdrop';
import Donut from '../fusionChart/donutChart';
import { useLocation } from 'react-router-dom';
import LocationIcon from '../../images/location@2x.png';
import UserIcon from '../../images/GlobalUser@2x.png';
import CompaniesIcon from '../../images/Companies Icon@2x.png';
import DealerIcon from '../../images/User-1.png';
import Batteries from '../../images/Battery Dashboard.png';
import SiteIcon from '../../images/Companies Icon@2x.png';
import * as roles from '../../settings/roles';
import CountryMap from '../../images/CountryMap.png';
import { resize, hideSidebar } from '../../settings/resize';
import defaultImage from '../../images/ampure_icon_58x58.png';
import mapBannerIcon from "../../images/mapBanner.svg";
import posiBanneLogo from "../../images/posilinkBannerLogo.svg";



ReactFC.fcRoot(FusionCharts, Maps, World, Usa, Charts, FusionTheme);

interface item {
    id: string;
    owner: { login: string },
    name: string,
    locationsCount: string,
    severeFaults: number,
    overdueTasks: number,
    upcomingTasks: number,
    orderId: number,
    imageData: any,
    imageName: any
}
const { Search } = Input;

interface props {
    Height: number,
    chartHeight: number
}

const DealerDashboard: FC<any> = ({ globalData, setGlobalData, setLabelName }) => {
    const [current, setCurrent] = useState<number>(1)
    const [minIndex, setMindex] = useState<number>(0)
    const [maxIndex, setMaxindex] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [showSizeChanger, setShowSizeChanger] = useState<boolean>(false)
    const [pageSizeOptions, setPageSizeOptions] = useState<[number, number, number, number]>([10, 20, 50, 100])
    const [companiesAlerts, setComapaniesAlerts] = useState<any[]>([])
    const [search, setSearch] = useState<string>("")
    const [taskSummary, setTaskSummary] = useState<any>({ severeFaults: 0, overdueTasks: 0, upcomingTasks: 0 })
    const [summary, setSummary] = useState<any>({ dealerCount: 0, companiesCount: 0, locationsCount: 0, usersCount: 0, siteCount: 0, divicesCount: 0 })
    const [soc, setSoc] = useState<any>({ highSOCUnits: 0, lowSOCUnits: 0, totalChargeCount: 0, totalDisconnectedCount: 0, totalInstalledUnits: 0, totalNoChargeUnits: 0 })
    const [sysSummary, setSysSummary] = useState<boolean>(true);
    const [operationStatus, setOperationStatus] = useState<any>({
        highTempBatteries: 0,
        totalAhsCharged: 0,
        totalCompletedUnits: 0,
        totalOverdueUnits: 0,
        totalUpcomingUnits: 0,
        totalWateringEvents: 0
    })
    const [date, setDate] = useState<{ startDate: string, endDate: string }>({ startDate: "", endDate: "" })
    const [countryRes, setCountryRes] = useState<any[]>([])
    const [count, setCount] = useState<any>(0)
    const [initial, setInitial] = useState<boolean>(true);
    const defervalue = useDeferredValue(search)
    const [loadingCount, setLoadingCount] = useState(0);
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)


    // to manage the loader
    const showLoader = () =>{
        setLoadingCount((count) => count + 1);
    } 
    const hideLoader = () => {
        setLoadingCount((count) => Math.max(count - 1, 0));
    }
    useEffect(()=>{
      setIsShowSpinner(loadingCount>0)
    },[loadingCount])



    useLayoutEffect(() => {
        let mapHeight = document.getElementById('countryChart')?.offsetHeight
        let searchAlertHeight: any = document.getElementById('search-alerts')?.offsetHeight
        let alertHeaderTitle: any = document.getElementById('summaryCardsTitle')?.offsetHeight
        let cardHeight = document.getElementById('company-card')?.offsetHeight
        let paginationHeight: any = document.getElementById('pagination-div')?.offsetHeight
        let sitetitleAndPaginationMargin = 35
        var rightSideHeight: any
        var remaningHeight: any
        var getLocalData: any

        if (cardHeight) {
            let cardCount = 10
            rightSideHeight = mapHeight + alertHeaderTitle + (cardHeight + 12) * cardCount
            getLocalData = localStorage.getItem('leftsideheight')
            if (getLocalData) { remaningHeight = parseInt(getLocalData) - (mapHeight + alertHeaderTitle + paginationHeight + searchAlertHeight + sitetitleAndPaginationMargin) }
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth < 1025 && window.innerWidth > 767) {
            let getDiv = document.querySelector("[class='company-alerts']") as HTMLElement
            getDiv.style.height = (remaningHeight) + 'px'
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth > 1900) {
            let getDiv = document.querySelector("[class='company-alerts']") as HTMLElement
            getDiv.style.height = (remaningHeight) + 'px'
        }
    });

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })

    useEffect(() => {
        resize();
        hideSidebar();
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('dealerDashBoard_W')) window.history.back()
        }


        let userInfo: any = Utility.getUserInfo();
        var userId = userInfo.userId;

        const today = new Date()
        today.setDate(today.getDate());
        const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
        const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');
        // setDate({ ...date, startDate: firstDayOfWeek, endDate: lastDayOfWeek })

        // setTimeout(() => {
            let data: any = sessionStorage.getItem('globalData');
            data = data && JSON.parse(data);

            let orgId = data?.orgId > 0 ? data?.orgId : userId;

            getTaskSummary(orgId, data?.divId, data?.locationId, data?.buildingId)
            getDealerSummary(orgId, data?.divId, data?.locationId, data?.buildingId)
            //getSocData(firstDayOfWeek, lastDayOfWeek, orgId, data?.divId, data?.locationId, data?.buildingId)
            //getOperationStatusData(firstDayOfWeek, lastDayOfWeek, orgId, data?.divId, data?.locationId, data?.buildingId)
            getCompantAlerts(orgId, data?.divId, data?.locationId, data?.buildingId);
            //countrySummary(orgId, data?.divId, data?.locationId, data?.buildingId)
            setInitial(false);
        // }, 1000);
    }, []);

    useEffect(() => {
        if (initial === false) {
            let userInfo: any = Utility.getUserInfo();
            let userId = userInfo.userId;

            const today = new Date()
            today.setDate(today.getDate());
            const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
            const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');

            let orgId = globalData?.orgId > 0 ? globalData?.orgId : userId;

            getTaskSummary(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            getDealerSummary(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            //getSocData(firstDayOfWeek, lastDayOfWeek, orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            getOperationStatusData(date.startDate, date.endDate, orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            getCompantAlerts(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId);
            //countrySummary(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId]);

    const getTaskSummary = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
        showLoader()
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let url = constantUrl.api.dashboard.getTaskSummary;
        let type = "POST";
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        }

        try {
            const response = await service({
                url: url,
                method: type,
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { severeFaults, overdueTasks, upcomingTasks } = response;
            setTaskSummary({ ...taskSummary, severeFaults, overdueTasks, upcomingTasks });
        } catch (error) {
            console.log(error)
        }
        finally{
            hideLoader()
        }
    }

    const getDealerSummary = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = "";

        // if (divId > 0) {
        url = constantUrl.api.dashboard.getCompanySummary;
        //     setSysSummary(false)
        // } else {
        //     url = constantUrl.api.adminDashboard.getSummary;
        //     setSysSummary(true)
        // }

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        }
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            const { dealerCount, companiesCount, locationsCount, usersCount, siteCount, divicesCount } = response
            setSummary({ ...summary, dealerCount, companiesCount, locationsCount, usersCount, siteCount, divicesCount })
        } catch (error) {
            console.warn(error);
        }finally{
            hideLoader()
        }

    }

    const getSocData = async (startDate: string, endDate: string, orgId: any, divisionId: any, locationId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getOperationSoc;

        let payload = {
            orgId: orgId,
            divisionId: divisionId,
            locationId: locationId,
            buildingId: buildingId,
            startDate: startDate,
            endDate: endDate
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits } = response
            setSoc({ ...soc, highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits })
        } catch (error) {
            console.warn("OperationSocApiError", error);
        }
    }

    const getOperationStatusData = async (startDate: string, endDate: string, orgId: any, divisionId: any, locationId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        var url = constantUrl.api.dashboard.getOperationStatus;

        let payload = {
            orgId: orgId,
            divisionId: divisionId,
            locationId: locationId,
            buildingId: buildingId,
            startDate: startDate,
            endDate: endDate,
            weekStartDay: localStorage.getItem("weeklyStartDay")
        }
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            const { highTempBatteries, totalAhsCharged, totalCompletedUnits, totalOverdueUnits, totalUpcomingUnits, totalWateringEvents } = response
            setOperationStatus({ ...operationStatus, highTempBatteries, totalAhsCharged, totalCompletedUnits, totalOverdueUnits, totalUpcomingUnits, totalWateringEvents })
        } catch (error) {
            console.warn("operationStatusApiError", error);
        }finally{
            hideLoader()
        }
    }

    const getCompantAlerts = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getCompanyAlerts;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        };
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            if (response) {
                const data = [...response]
                setComapaniesAlerts(data)
                setCount(response.length)
                response.length > 10 && setShowSizeChanger(true)
                setMindex(0)
                setMaxindex(pageSize)
            }
        } catch (error) {
            setIsShowSpinner(false)
            console.warn(error);
        }finally{
            hideLoader()
        }
    }

    const countrySummary = async (orgId: any, divisionId: any, locationId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getCountrySummary;
        let payload = {
            orgId: orgId,
            divisionId: divisionId,
            locationId: locationId,
            buildingId: buildingId,
            countryCode: "USA"
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });

            setCountryRes(response)
        } catch (error) {
            console.log("getCountrySummaryApiError:", error)
        }
    }

    const dateFilter = (startDate: string, endDate: string,getGlobalData?:any) => {
        // let data: any = sessionStorage.getItem('globalData');
        // data = data && JSON.parse(data);
        setDate({ ...date, startDate, endDate });
        getOperationStatusData(startDate, endDate, getGlobalData?.orgId, getGlobalData?.divId, getGlobalData?.locationId, getGlobalData?.buildingId)
    }

    const handleChange = (current: number, pageSize: number) => {
        setCurrent(current);
        setMindex((current - 1) * pageSize)
        setMaxindex(current * pageSize);
        setPageSize(pageSize);
        // setPageoffset({pageLimit:pageSize,pageOffset:current})
    };

    const filterComapnyName = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        value.length < 3 ? setSearch("") : setSearch(value)
    }

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="main-panel">
                <div className="ChartPanel">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7 left-main-col" id='leftCol'>
                                <TaskSummary
                                    summaryData={taskSummary}
                                />
                                <div className="row left-admin-row justify-content-between mt-3  ">
                                    <div className="col-md-12 col-lg-12 col-xl-6">
                                        {/* SYSTEM SUMMARY   */}
                                        {/* {
                                            sysSummary ?
                                                <Summary
                                                    heading='DEALER SUMMARY'
                                                    summaryData={summary}
                                                    orgID={data?.orgId}
                                                    DealerIcon={DealerIcon}
                                                    CompaniesIcon={CompaniesIcon}
                                                    LocationIcon={LocationIcon}
                                                    UserIcon={UserIcon}
                                                    dealerLabel={'DEALERS'}
                                                    comapniesLabel={'COMPANIES'}
                                                    locationLabel={'LOCATIONS'}
                                                    userLabel={'USERS'}
                                                />
                                                : */}
                                        <Summary
                                            heading='DEALER SUMMARY'
                                            summaryData={summary}
                                            orgID={data?.orgId}
                                            SiteIcon={SiteIcon}
                                            batteryIcon={Batteries}
                                            LocationIcon={LocationIcon}
                                            UserIcon={UserIcon}
                                            siteLable={'SITES'}
                                            deviceLabel={'DEVICES'}
                                            locationLabel={'LOCATIONS'}
                                            userLabel={'USERS'}
                                        />
                                        {/* } */}

                                        {/* STATE OF CHARGE  */}
                                        {/* <div className="row state-charge" id='state-charge'>
                                            <div className="col-12 px-2 mt-2 mb-2">
                                                <div className="col-12  chartTitle">STATE OF CHARGE</div>
                                            </div> */}
                                        {/* doughnut chart  */}
                                        {/* <div className="col-md-12 pb-2 px-2 pie-chart" id="pie-chart">
                                                <Donut
                                                    pieRaidus={70}
                                                    doughnutRadius={60}
                                                    soc={soc}
                                                />
                                            </div> */}
                                        {/* doughnut chart  */}
                                        {/* </div> */}
                                    </div>
                                    {/* OPERATIONS STATUS  */}
                                    <div className="col-md-12 col-lg-12 col-xl-6 operation-status-container" id='operation-status-container'>
                                        <OperationStatus
                                            statusData={operationStatus}
                                            dateFilter={dateFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 pr-0 company-alert" id="dealear-dashboard">
                                <div className="row">
                                  
                                    <div className="col-md-12 pl-0 pie-chart" id="countryChart" style={{ height: "180px" }}>
                                     <div className="mapBannerDiv">
				    			 		{/* <img src={WorldMap} style={{ width: "inherit", height: "200px" }} alt='img' /> */}
				                                        <div className="row h-100">
								            <div className="col-md-5 mapBannerImgDiv h-100">
								              <img
								                src={mapBannerIcon}
								                className="mapBannerImg"
								                alt="mapBanner"
								              />
								            </div>
								            <div className="col-md-6">
								              <div className="mapBannerLogoDiv">
								                <img
								                  src={posiBanneLogo}
								                  alt="posilinkLogo"
								                  className="mapBannerDivLogo"
								                />
								              </div>
								              <label className="mapBannerDivText">
								                Welcome to Posilink, a<br></br> comprehensive
								                battery<br></br> management tool
								              </label>
								            </div>
								          </div>
								        </div>
                                       </div>

                                </div>
                                <div className="row" id='alerts'>
                                    <div className="col-12 pl-0">
                                        <div className="chartTitle mt-2 mb-2" id="summaryCardsTitle">COMPANY ALERTS</div>
                                        {/* <div className='search-alerts mb-1' id='search-alerts'>
                                            <Search placeholder="Search company alerts"
                                                onChange={filterComapnyName}
                                                style={{}}
                                                allowClear
                                                enterButton />
                                        </div> */}
                                        <div id="company-cards">
                                            <ul id='company-alerts' className='company-alerts'>
                                                {companiesAlerts && companiesAlerts.filter((item: any, index: any) => {
                                                    return defervalue.toLowerCase() === "" ? item : item?.name?.toLowerCase()?.includes(defervalue.toLowerCase())
                                                }).map((itemData: item, index: number) => {
                                                    const { name, locationsCount, severeFaults, overdueTasks, upcomingTasks, orderId, imageData, imageName } = itemData
                                                    return index >= minIndex &&
                                                        index < maxIndex && (
                                                            <div id='company-card' key={index} className="row dealer-card">
                                                                <div className="col-md-12">
                                                                    <div className="dealer-card-body">
                                                                        <div className="delear-body-right">
                                                                            <div>
                                                                                {/* <img src={imageData ? imageData : defaultImage} className="dealear-img" alt='img' /> */}
                                                                                {/* <img src={`data:image/png;base64,${imageData ? imageData : defaultImage}`} className="dealear-img" alt='img' /> */}
                                                                                <img src={imageData ? `data:image/png;base64,${imageData}` : defaultImage} className="dealear-img" alt='img' />
                                                                            </div>
                                                                            <div>
                                                                                <div className="dealaer-no" onClick={() => {
                                                                                    let data: any = sessionStorage.getItem('globalData');
                                                                                    data = data && JSON.parse(data);
                                                                                    data["divId"] = orderId;
                                                                                    sessionStorage.setItem("globalData", JSON.stringify(data))
                                                                                    setLabelName("company", name);
                                                                                    setGlobalData("divName", name);
                                                                                }}><Link to={"/companydashboard"}>{name?.length > 15 ? name?.substring(0, 15) + "..." : name}</Link></div>
                                                                                <div className="fontBlue ">{locationsCount}&nbsp; Locations</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="delear-body-left ">
                                                                            <div>
                                                                                <div className="dot-red"></div>
                                                                                <div className="dot-yellow"></div>
                                                                                <div className="dot-green"></div>
                                                                            </div>
                                                                            <div className="count">
                                                                                <div>{severeFaults}</div>
                                                                                <div>{overdueTasks}</div>
                                                                                <div>{upcomingTasks}</div>
                                                                            </div>
                                                                            <div className="delaler-status">
                                                                                <div>SEVERE FAULTS</div>
                                                                                <div>OVERDUE TASKS</div>
                                                                                <div>UPCOMING TASKS</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                })}
                                            </ul>
                                        </div>
                                        {/* <div id='pagination-div' className="mt-2 pagination-div">
                                            <Pagination
                                                pageSize={pageSize}
                                                current={current}
                                                total={count}
                                                onChange={handleChange}
                                                pageSizeOptions={pageSizeOptions}
                                                showSizeChanger={showSizeChanger}
                                                showLessItems={true}
                                                style={{ bottom: "0px", textAlign: "center" }}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default DealerDashboard;