import React, { FC, useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Modal, Form, Input, Button, Select, Tooltip, ConfigProvider, AutoComplete } from "antd";
import type { SelectProps } from 'antd/es/select';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, CaretDownOutlined, CaretUpOutlined, SearchOutlined } from "@ant-design/icons";

import { resize, hideSidebar } from '../../settings/resize';
import constant1 from '../../settings/constants';
import service from "../../service/api/common";
import constants from "../../settings/constants";
import { Utility } from "../../helper/index";
import LoadingSpinner from '../spinner/loading-spinner'
import SessionWarning from '../reusableComponent/sessionWarning';
import * as roles from '../../settings/roles';

import CountryList from "../reusableComponent/country";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";

interface props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    buildingName: string,
    batteryId: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  }
  refresh: number
}

const req = {
  pageOffset: 1,
  pageLimit: 10,
  buildingName: "",
  orgId: 0,
  sortColumns: "buildingName",
  sortDirection: "ASC",
  locationId: -1,
  buildingId: -1,
  divisionId: -1
}

const SiteMaintenance: FC<props> = ({ globalData, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;

  const [reqBody, setReqBody] = useState<any>(req)
  const [countReq, setCountReq] = useState<any>(req)
  const [show, setShow] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [toggleDelete, setToggleDelete] = useState(false)
  const [url, setUrl] = useState<string>("")
  const [Title, setTitle] = useState<string>("")
  const [id, setId] = useState<number>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [initialValue2, setInitialValue] = useState<any>({ buildingId: null, orgId: null, locationName: "", orgName: "", divisionId: null, locationId: null, identifier: "", buildingName: "", buildingAddress: "", buildingCountry: "", contactName: "", contactEmail: "", contactTelephone: "", deleted: "", dateDeleted: "", dateUpdated: "", status: "" });
  const [isSiteUpdated, setIsSiteUpdated] = useState<boolean>(false)
  const [isSiteDeleted, setIsSiteDeleted] = useState<boolean>(false)
  const [sort, setSort] = useState<any>({ org: false, locationName: false, buildingName: false, buildingAddress: false, buildingCountry: false, contactName: false, contactEmail: false, contactTelephone: false, deleted: false })
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const tabelRef = useRef<HTMLDivElement>(null)
  const [orgList, setOrgList] = useState<null[]>([])
  const [locations, setLocationList] = useState<any[]>([]);
  const [buildingId, setBuildingId] = useState<number>(0)
  const [locationId, setLocationId] = useState<number>(0)
  const [locationName, setLocationName] = useState<string>("")
  const [divisionId, setDivisionId] = useState<number>(0)
  const [divisionName, setDivisionName] = useState<string>("")

  const [orgId, setOrgId] = useState<number>(0)
  const [orgName, setOrgName] = useState<string>("")
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [siteQuery, setSiteQuery] = useState<string>("")
  const [initial, setInitial] = useState<boolean>(true);
  const [siteEmpty, setSiteEmpty] = useState<boolean>(false)
  const [divisions, setDivisions] = useState<any[]>([]);

  const [addreq, setAddReq] = useState<any>({ organizationID: 0, divisionId: 0, locationId: 0, buildingId: 0 });

  const location = useLocation();
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm()

  const [tableHeight, setTableHeight] = useState<any>(410)
  const filtersRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)

  const blueColor = "#004f9f"
  const grayColor = "#666666"
  const tooltipColor = "#7a8185"

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    let marginAndPaddings: any = 90
    let navHeight = localStorage.getItem('navHeight')
    let filtersHeight
    let paginationHeight
    if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 410 ? setTableHeight(410) : setTableHeight(tableHeight)
  };

  const showAddUser = () => {
    setShow(!show)
    form.resetFields()
  }

  async function getOrganization(orgid: any, divid: any, locid: any, siteid: any) {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let url = constants.api.dashboard.getOrganizations;

    let payload = {
      "orgId": orgid,
      "divisionId": divid,
      "locationId": locid,
      "buildingId": siteid
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      const org = await response;
      setOrgList(org);
    } catch (error) {
      console.log("getOrgApiError:", error);
    }
  }

  const getLocations = async (divId: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    setAddReq({ ...addreq, divisionId: divId });

    let url = constant1.api.dashboard.getLocations;

    let payload = {
      "orgId": addreq?.organizationID,
      "divisionId": divId,
      "locationId": -1,
      "buildingId": -1
    }

    if (roles.COMPANY_ADMIN === localStorage?.UserType || roles.LOCATION_ADMIN === localStorage?.UserType) {
      payload.orgId = Number(localStorage.accountId)
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: 'Bearer ' + token },
        data: payload
      })
      setLocationList(response);

      if (response.length === 0) {
        updateForm.setFieldsValue({ u_locationName: "" })
        setLocationId(-1)
        setLocationName("")
      }
    } catch (error) {
      console.error('locationList:', error)
    }
  }

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('siteMaintenanceR_W')) window.history.back()
    }

    let userInfo: any = Utility.getUserInfo();
    var gettoken = userInfo.authToken;
    var loginDealerId = userInfo.userId;

    setInitial(true);
    resize();
    hideSidebar();

    setToken(gettoken)
    setUrl(constants.api.site.postSite)

    if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
      getOrganization(0, 0, 0, 0);
    } else {
      getOrganization(loginDealerId, 0, 0, 0);
    }

    if (roles.COMPANY_ADMIN === localStorage?.UserType || roles.LOCATION_ADMIN === localStorage?.UserType) {
      getLocations(Number(localStorage.divisionId));
    }

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let org = location.state?.orgID > -1 ? location.state?.orgID : data?.orgId > -1 ? data?.orgId : userId;
    let loc = data?.locationId > -1 ? data?.locationId : -1;
    let buildId = data?.buildingId > -1 ? data?.buildingId : -1;

    getSiteData({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: loc, buildingId: buildId })
    getCount({ ...countReq, orgId: org, divisionId: data?.divId, locationId: loc, buildingId: buildId })

    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: loc, buildingId: buildId })
    setCountReq({ ...countReq, orgId: org, divisionId: data?.divId, locationId: loc, buildingId: buildId })
    setInitial(false)
  }, [])

  useEffect(() => {
    if (initial === false) {
      setSiteQuery("")
      getSiteData({ ...reqBody, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, buildingName: "" })
      getCount({ ...countReq, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, buildingName: "" })

      setReqBody({ ...reqBody, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, buildingName: "" })
      setCountReq({ ...countReq, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, buildingName: "" })
      setCurrent(1)
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId])

  useEffect(() => {
    if (refresh === 0) return
    getSiteData({ ...reqBody })
    getCount({ ...countReq })
  }, [refresh]);

  async function getSiteData(load: any) {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    let userId = userInfo.userId;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let payload = load;
    payload.orgId = data?.orgId > -1 ? data?.orgId : userId;

    setIsShowSpinner(true);

    try {
      const response = await service({
        url: constants.api.site.getSite,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });

      setTableData(response);
      setIsShowSpinner(false);
    } catch (error) {
      console.error("getSite:", error);
      setIsShowSpinner(false)
    }
  }

  const getCount = async (load: any) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    let userId = userInfo.userId

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let payload = load;
    payload.orgId = data?.orgId > -1 ? data?.orgId : userId;

    var url = constants.api.site.getCount;

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTotalCount(response)
    } catch (error) {
      console.log(error);
    }
  };

  const showUpdateSite = useCallback(
    function useCallback1(item: any) {
      setIsModalOpen(true);
      const { buildingId, orgId, orgName, locationName, divisionId, divisionName, locationId, identifier, buildingName, buildingAddress, buildingCountry, contactName, contactEmail, contactTelephone, deleted, dateDeleted, dateUpdated, status } = item;
      setAddReq({ ...addreq, organizationID: orgId });
      setBuildingId(buildingId)
      setLocationId(locationId)
      setLocationName(locationName)
      setOrgId(orgId)
      setOrgName(orgName)
      setDivisionId(divisionId)
      setDivisionName(divisionName)
      updateForm.setFieldsValue({
        u_buildingId: buildingId,
        u_siteName: buildingName,
        u_address: buildingAddress,
        u_country: buildingCountry,
        u_contactName: contactName,
        email: contactEmail,
        u_contactNumber: contactTelephone,
        u_orgName: orgName,
        u_locationName: locationName,
        u_status: status,
        u_division: divisionName
      })
      // getDivisions(orgId);
      // getLocations(divisionId)
    },
    [initialValue2]
  );

  async function handleUpdate(value: any) {
    const { u_siteName, u_address, u_country, u_contactName, email, u_contactNumber, u_orgName, u_locationName, u_status, u_division } = value;
    const u_org = orgList?.find((item: any) => item.orgName === u_orgName);
    var u_loc;
    var u_div;

    if (locations.length > 0) {
      u_loc = locations.find((item: any) => item.locationId === u_locationName)
    }
    if (divisions.length > 0) {
      u_div = divisions.find((item: any) => item.divisionId === u_division)
    }

    let toUpdateData = {
      buildingId: buildingId,
      buildingName: u_siteName,
      buildingAddress: u_address,
      buildingCountry: u_country,
      contactName: u_contactName,
      contactEmail: email,
      contactTelephone: u_contactNumber,
      orgId: u_org?.['orgId'] ? u_org?.['orgId'] : orgId,
      orgName: u_org?.['orgName'] ? u_org?.['orgName'] : orgName,
      locationId: u_loc?.['locId'] ? u_loc?.['locId'] : locationId,
      locationName: u_loc?.['locationName'] ? u_loc?.['locationName'] : locationName,
      divisionId: u_div?.['divisionId'] ? u_div?.['divisionId'] : divisionId,
      divisionName: u_div?.['divisionName'] ? u_div?.['divisionName'] : divisionName,
    }

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    try {
      const response = await service({
        url: constant1.api.site.putSite,
        method: "PUT",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: toUpdateData
      });

      setIsModalOpen(false);
      setMessage('Site Updated Successfully')
      getSiteData({ ...reqBody })
      setIsSiteUpdated(true)
    } catch (error) {
      console.log("UpdateSite:", error);
    }
  }

  const validateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  };

  const updateValidateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  };

  async function onAddFinish(value: any) {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let payload = value;
    if (roles.COMPANY_ADMIN === localStorage?.UserType ||
      roles.COMPANY_MANAGER === localStorage?.UserType ||
      roles.LOCATION_ADMIN === localStorage?.UserType ||
      roles.LOCATION_MANAGER === localStorage?.UserType ||
      roles.SITE_ADMIN === localStorage?.UserType ||
      roles.SITE_MANAGER === localStorage?.UserType
    ) {
      payload.orgId = Number(localStorage.accountId)
      payload.divisionId = Number(localStorage.divisionId)
    }

    if (roles.LOCATION_ADMIN === localStorage?.UserType ||
      roles.LOCATION_MANAGER === localStorage?.UserType ||
      roles.SITE_ADMIN === localStorage?.UserType ||
      roles.SITE_MANAGER === localStorage?.UserType
    ) {
      payload.locationId = Number(localStorage.locationId)
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", "content-type": "application/json", Authorization: "Bearer " + token },
        data: value,
      });
      if (response.status === "SUCCESS") {
        getSiteData({ ...reqBody })
        setShow(false)
        setMessage('Site Submitted Successfully')
        setIsSiteUpdated(true)
      }
      if (response.status === "FAILURE" && response.errorMessage === "Site already present" && response.errorCode === 1111) {
        setIsSiteUpdated(true)
        setMessage(response.errorMessage)
      }
      console.log("response:", response)
    } catch (error: any) {
      if (error.status === 400) {
        const { data } = error
        if (data.contactName) {
          form.setFields([{ name: 'contactName', errors: [data.contactName] }])
        }
        if (data.contactTelephone) {
          form.setFields([{ name: 'contactTelephone', errors: [data.contactTelephone] }])
        }
        if (data.contactEmail) {
          form.setFields([{ name: 'contactEmail', errors: [data.contactEmail] }])
        }
        if (data.buildingName) {
          form.setFields([{ name: 'buildingName', errors: [data.buildingName] }])
        }
      }
      console.warn(error);
    }
  }

  async function deleteSiteList(buildingId: number) {
    setToggleDelete(!toggleDelete)
    setId(buildingId)
  }

  const okDeleteSite = async () => {
    setToggleDelete(!toggleDelete)
    try {
      const response = await service({
        url: constants.api.site.deleteSite + id,
        method: "DELETE",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      if (response.status === "SUCCESS") {
        setTitle("Site Deactivated Successfully")
        setIsSiteDeleted(true)
      }
      if (response.status === "FAILURE") {
        setMessage(response.errorMessage)
        setTitle(response.errorMessage)
        setIsSiteDeleted(true)
      }
    } catch (error) {
      console.warn("dealerDelete:", error)
    }
  };

  const okSiteDeleted = () => {
    setIsSiteDeleted(false)
    setReqBody({ ...reqBody, pageOffset: 1 })
    window.location.reload()
  }

  const okSiteUpdated = () => {
    setIsSiteUpdated(false)
    setReqBody({ ...reqBody, pageOffset: 1 })
    window.location.reload()
  }

  const changeAddress = (event: any) => {
    setInitialValue({ ...initialValue2, buildingAddress: event.target.value })
  }

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case "org":
        if (sort.org) {
          setReqBody({ ...reqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "locationName":
        if (sort.locationName) {
          setReqBody({ ...reqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "buildingName":
        if (sort.buildingName) {
          setReqBody({ ...reqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "buildingAddress":
        if (sort.buildingAddress) {
          setReqBody({ ...reqBody, sortColumns: "buildingAddress", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "buildingAddress", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "buildingAddress", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "buildingAddress", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "buildingCountry":
        if (sort.buildingCountry) {
          setReqBody({ ...reqBody, sortColumns: "buildingCountry", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "buildingCountry", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "buildingCountry", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "buildingCountry", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'contactName':
        if (sort.contactName) {
          setReqBody({ ...reqBody, sortColumns: "contactName", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "contactName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contactName", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "contactName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'contactEmail':
        if (sort.contactEmail) {
          setReqBody({ ...reqBody, sortColumns: "contactEmail", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "contactEmail", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contactEmail", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "contactEmail", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'contactTelephone':
        if (sort.contactTelephone) {
          setReqBody({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "contactTelephone", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case 'deleted':
        if (sort.deleted) {
          setReqBody({ ...reqBody, sortColumns: "deleted", sortDirection: "ASC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "deleted", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setReqBody({ ...reqBody, sortColumns: "deleted", sortDirection: "DESC", pageOffset: 1 })
          getSiteData({ ...reqBody, sortColumns: "deleted", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  }

  var timer: any;
  const handleSearch = (value: string) => {
    let val = value ? value?.trim() : "";
    setSiteQuery(val)
    setSiteEmpty(false)
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (value === "") {
        setCountReq({ ...countReq, buildingName: "", pageOffset: 1 })
        setReqBody({ ...reqBody, buildingName: "", pageOffset: 1 })
        getSiteData({ ...reqBody, buildingName: "", pageOffset: 1 })
        getCount({ ...countReq, buildingName: "", pageOffset: 1 })
        setCurrent(1);
      } else {
        setReqBody({ ...reqBody, buildingName: val, pageOffset: 1 })
        setCountReq({ ...countReq, buildingName: val, pageOffset: 1 })
        getSiteData({ ...reqBody, buildingName: val, pageOffset: 1 })
        getCount({ ...countReq, buildingName: val, pageOffset: 1 })
        setCurrent(1);
      }
    }, 1000)
  }

  const searchOnSelect = (value: string) => {
    setReqBody({ ...reqBody, buildingName: value, pageOffset: 1 })
    setCountReq({ ...countReq, buildingName: value, pageOffset: 1 })
    getSiteData({ ...reqBody, buildingName: value, pageOffset: 1 })
    getCount({ ...countReq, buildingName: value, pageOffset: 1 })
    setCurrent(1);
  };

  const onClearGetCall = () => { }

  const callLocationApi = () => {
    if (!siteQuery) {
      setSiteEmpty(true)
    } else {
      setSiteEmpty(false)
    }
    if (!siteQuery) return
    setCountReq({ ...countReq, buildingName: siteQuery, pageOffset: 1 })
    setReqBody({ ...reqBody, buildingName: siteQuery, pageOffset: 1 })
    getSiteData({ ...reqBody, buildingName: siteQuery, pageOffset: 1 })
    getCount({ ...countReq, buildingName: siteQuery, pageOffset: 1 })
    setCurrent(1)
  }

  const okButtonStyle = {
    background: '#004f9f',
    color: '#fff',
  }

  const selectOrg = (value: any) => {
    const org: any = orgList.find((item: any) => item.orgName === value)
    org && getLocations(org?.orgId)
  }

  const handlePagination = (pageNumber: number) => {
    setReqBody({ ...reqBody, pageOffset: pageNumber });
    getSiteData({ ...reqBody, pageOffset: pageNumber })
    setCurrent(pageNumber);
  }

  const onSelect = (getPageSize: string) => {
    const numPageSize = Number(getPageSize)
    setPageSize(numPageSize)
    setCurrent(1);
    setReqBody({ ...reqBody, pageLimit: getPageSize, pageOffset: 1 })
    getSiteData({ ...reqBody, pageLimit: getPageSize, pageOffset: 1 })
  }

  const getDivisions = async (orgid: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    setAddReq({ ...addreq, organizationID: orgid });

    var url = constant1.api.location.getDivisions;
    let payload = { orgId: orgid };

    if (url) {
      try {
        const response = await service({
          url: url,
          method: "POST",
          headers: { accept: "*/*", Authorization: "Bearer " + token },
          data: payload
        });

        setDivisions(response);
      }
      catch (error) {
        console.log("getDivisionsError:", error)
      }
    }
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className="container-fluid px-0 marginT">
        {localStorage.getItem('permissions')?.includes('buildingadd_W') ? <>
          <div className=" row nav-filter align-items-center">
            <div className="col-12  tab_content-battery">
              <span onClick={showAddUser} className="d-flex align-items-center">
                {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                <span className="pl-2 paddingT">Add New Site</span>
              </span>
            </div>
          </div>
        </> : <></>}
        {show ? (
          <>
            <div
              className={show ? "show-user  add-user" : "hide-user add-user"}>
              <ConfigProvider
                theme={{ token: { colorPrimary: "#004f9f" } }}>
                <Form
                  form={form}
                  onFinish={onAddFinish}
                  layout="vertical"
                  validateMessages={validateMessages}>
                  <div className='row'>
                    <div className='col-6'>
                      {
                        (roles.PLATFORM_ADMIN === localStorage?.UserType ||
                          roles.DEALER_ADMIN === localStorage?.UserType) ?
                          <Form.Item
                            style={{ marginBottom: "5px" }}
                            label="Select Organization"
                            name="orgId"
                            rules={[{ required: true, message: 'Please Select Organization' }]}
                          >
                            <Select
                              onChange={(valk) => {
                                getDivisions(valk);
                                form.setFieldsValue({ orgId: valk, divisionId: null, locationId: null });
                                setLocationList([])
                              }}
                              dropdownStyle={{ zIndex: 900 }}
                              placeholder="Select Organization">
                              {orgList && orgList.map((item: any, index: any) => {
                                return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                              })}
                            </Select>
                          </Form.Item> :
                          <Form.Item
                            style={{ marginBottom: "5px" }}
                            label="Select Organization"
                          >
                            <Select placeholder="Select Organization" disabled value={localStorage.accountName}></Select>
                          </Form.Item>

                      }
                    </div>
                    <div className='col-6'>
                      {
                        (roles.PLATFORM_ADMIN === localStorage?.UserType ||
                          roles.PLATFORM_MANAGER === localStorage?.UserType ||
                          roles.DEALER_ADMIN === localStorage?.UserType ||
                          roles.DEALER_MANAGER === localStorage?.UserType
                        ) ?
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Division"
                            name="divisionId"
                            rules={[{ required: true, message: 'Please Select Division' }]}
                          >
                            <Select
                              dropdownStyle={{ zIndex: 900 }}
                              onChange={(valk) => { getLocations(valk); form.setFieldsValue({ divisionId: valk, locationId: null }); }}
                              placeholder="Select Division">
                              {divisions && divisions.map((item: any, index: any) => {
                                return <Select.Option value={item?.divisionId}>{item?.divisionName}</Select.Option>
                              })}
                            </Select>
                          </Form.Item> :
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Division"
                          >
                            <Select placeholder="Select Division" disabled value={localStorage.divisionName}></Select>
                          </Form.Item>
                      }
                    </div>
                    <div className='col-6'>
                      {
                        (roles.PLATFORM_ADMIN === localStorage?.UserType ||
                          roles.PLATFORM_MANAGER === localStorage?.UserType ||
                          roles.DEALER_ADMIN === localStorage?.UserType ||
                          roles.DEALER_MANAGER === localStorage?.UserType ||
                          roles.COMPANY_ADMIN === localStorage?.UserType ||
                          roles.COMPANY_MANAGER === localStorage?.UserType
                        ) ?
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Location"
                            name="locationId"
                            rules={[{ required: true, message: 'Please Select Location' }]}
                          >
                            <Select
                              dropdownStyle={{ zIndex: 900 }}
                              placeholder="Select Location">
                              {locations && locations.map((item: any, index: any) => {
                                return <Select.Option value={item?.locId} key={index}>{item?.locationName}</Select.Option>
                              })}
                            </Select>
                          </Form.Item> :
                          <Form.Item style={{ marginBottom: "5px" }}
                            label="Location"
                          >
                            <Select placeholder="Select Location" disabled value={localStorage.locationName}></Select>
                          </Form.Item>
                      }
                    </div>
                    <div className='col-6'>
                      <Form.Item style={{ marginBottom: "5px" }}
                        label="Site Name"
                        name="buildingName"
                        rules={[{ required: true, message: 'Please Enter Site Name' }]}
                      >
                        <Input placeholder="Enter Site Name" />
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item style={{ marginBottom: "5px" }}
                        label="Contact Name"
                        name="contactName"
                        rules={[{ required: true, message: 'Please Enter Contact Name' }]}
                      >
                        <Input placeholder="Enter Contact Name" />
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item
                        name="buildingAddress"
                        label="Site Address"
                        style={{ marginBottom: "5px" }}
                      >
                        <Input placeholder="Enter Site Address" />
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item
                        name='contactEmail'
                        label="Contact Email"
                        rules={[{ type: 'email', required: true }]}
                        style={{ marginBottom: "5px" }}
                        required
                      >
                        <Input placeholder="Enter Email Address" />
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item style={{ marginBottom: "5px" }}
                        label="Site Country"
                        name="buildingCountry"
                        rules={[{ required: true, message: 'Please Select Country' }]}
                      >
                        <Select
                          showSearch
                          dropdownStyle={{ zIndex: 900 }}
                          placeholder="Select Country">
                          {CountryList?.map((item: any, index: any) => {
                            return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className='col-6'>
                      <Form.Item
                        name="contactTelephone"
                        label="Contact Number"
                        rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                        style={{ marginBottom: "5px" }}>
                        <Input type="tel" placeholder="Enter Contact Number" maxLength={10} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          onClick={showAddUser}
                          style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="pl-3">
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                          htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </ConfigProvider>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <ConfigProvider
        theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          className="update-users"
          title="Modify Site Information"
          centered
          open={isModalOpen}
          footer={null}
          onCancel={() => { setIsModalOpen(false) }}
          okButtonProps={{ style: okButtonStyle }}
        >
          <Form layout="vertical"
            onFinish={handleUpdate}
            validateMessages={updateValidateMessages}
            form={updateForm}
          >
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Site Name"
              name="u_siteName"
              rules={[
                { required: true, message: "Please Enter Site Name" },
              ]}
            >
              <Input
                disabled
                placeholder="Enter Site Name"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "5px" }}
              label="Address"
              name="u_address"
            >
              <Input
                placeholder="Enter Your Address"
                value={initialValue2.buildingAddress}
                onChange={changeAddress}
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Site Country"
                  name="u_country"
                  rules={[{ required: true, message: 'Please Site select country' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select Country"
                  >
                    {CountryList?.map((item: any, index: any) => {
                      return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Contact Name"
                  name="u_contactName"
                  rules={[{ required: true, message: "Please Enter Contact Name" }]}
                  required
                >
                  <Input
                    placeholder="Enter Contact Name"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Email Address"
                  name="email"
                  rules={[{ type: 'email', required: true }]}
                  required
                >
                  <Input
                    placeholder="Enter Email"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  label="Contact Number"
                  name="u_contactNumber"
                  rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                >
                  <Input
                    type="tel"
                    placeholder="Enter Contact Number"
                    maxLength={10}
                  />
                </Form.Item>
              </div>
              <div className='col-6'>
                <Form.Item style={{ marginBottom: "5px" }}
                  label="Organization"
                  name="u_orgName"
                >
                  <Select
                    showSearch
                    onSelect={selectOrg}
                    disabled
                    placeholder="Select Organization">
                    {orgList?.map((item: any, index: any) => {
                      return <Select.Option key={index} value={item.orgName}>{item.orgName}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-6'>
                <Form.Item style={{ marginBottom: "5px" }}
                  label="Division"
                  name="u_division"
                >
                  <Select
                    onChange={(valk) => { getLocations(valk); }}
                    placeholder="Select Division" disabled
                  >
                    {divisions && divisions.map((item: any, index: any) => {
                      return <Select.Option value={item?.divisionId}>{item?.divisionName}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className='col-6'>
                <Form.Item style={{ marginBottom: "5px" }}
                  label="Location"
                  name="u_locationName"
                >
                  <Select
                    placeholder="Select Location" disabled>
                    {locations && locations?.map((item: any, index: any) => {
                      return <Select.Option value={item?.locationName}>{item?.locationName}</Select.Option>
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex justify-content-end">
                  <div>
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        onClick={() => setIsModalOpen(false)}
                        style={{ color: "#004f9f", }}>
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                  <div className="pl-3">
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                        htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        theme={{ token: { colorPrimary: '#004f9f' } }}>
        <Modal open={isSiteUpdated} closable={false} centered title={message}
          footer={[<Button style={{ background: '#004f9f', color: "white" }} onClick={okSiteUpdated}>OK</Button>]}
        ></Modal>
        <Modal open={toggleDelete} okButtonProps={{ color: "#004f9f" }} centered title={"Are You Sure Want To Deactivate This Site?"} onOk={okDeleteSite} onCancel={() => setToggleDelete(false)} ></Modal>
        <Modal open={isSiteDeleted} closable={false} centered title={Title}
          footer={[<Button style={{ background: '#004f9f', color: "white" }} onClick={okSiteDeleted}>OK</Button>]}
        ></Modal>
        <Modal okButtonProps={{ color: "#004f9f" }} centered title={Title} open={isOpen} onOk={() => setIsOpen(!isOpen)} onCancel={() => setIsOpen(!isOpen)} ></Modal>
      </ConfigProvider>

      <div ref={filtersRef} className="auto_complete search-org pt-2">
        <AutoComplete
          size='middle'
          style={{ width: 300 }}
          options={options}
          onSelect={searchOnSelect}
          onSearch={handleSearch}
          allowClear
          onClear={onClearGetCall}
          placeholder="Search Site Name"
          value={siteQuery}
        >
        </AutoComplete>
        <span onClick={callLocationApi} className="search_icon"><SearchOutlined style={{ color: 'white' }} /></span>
        {siteEmpty && <div style={{ color: "red" }}>Please Enter Site Name</div>}
      </div>
      <div id="reporting_chart5" className="container-fluid pr-0 pl-0">
        <div className="row">
          <div className="col-12 ">
            <div className="container-daily-uses mt-2">
              <div className="bgWhite">
                <div className="chartTitle py-2 d-flex">
                  <span>Sites</span>
                  <div className='ml-auto show-entries'>
                    <ShowEntries getPageSize={onSelect} />
                  </div>
                </div>
                <div style={{ height: tableHeight }} ref={tabelRef} className="overflow-auto customScroll">
                  {/* className="tableWrapper-org" */}
                  <table id="" className="billable" style={{ width: "100%" }}>
                    <thead className='tableHeader'>
                      <tr className="billable-head">
                        <th>
                          <div onClick={() => sortBy('org')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Organization</span>
                            {sort.org ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('locationName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Location</span>
                            {sort.locationName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('buildingName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Site Name</span>
                            {sort.buildingName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('buildingAddress')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Site Address</span>
                            {sort.buildingAddress ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('buildingCountry')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Site Country</span>
                            {sort.buildingCountry ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('contactName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Contact Name</span>
                            {sort.contactName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('contactEmail')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Contact Email</span>
                            {sort.contactEmail ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('contactTelephone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Contact Number</span>
                            {sort.contactTelephone ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        <th>
                          <div onClick={() => sortBy('deleted')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                            <span>Deleted</span>
                            {sort.deleted ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                          </div>
                        </th>
                        {( localStorage.getItem('permissions')?.includes('buildingupdate_U') || localStorage.getItem('permissions')?.includes('buildingdelete_D') ) && 
                        <th>Action</th>
                        }
                      </tr>
                    </thead>

                    <tbody>
                      {
                        tableData?.map((item: any, index: any) => {
                          const { buildingId, orgName, locationName, buildingName, buildingAddress, buildingCountry, contactName, contactEmail, contactTelephone, deleted, status } = item;
                          return (
                            <tr key={index}>
                              <td>{orgName}</td>
                              <td>{locationName}</td>
                              <td>{buildingName}</td>
                              <td>{buildingAddress}</td>
                              <td>{buildingCountry} </td>
                              <td>{contactName}</td>
                              <td>{contactEmail}</td>
                              <td>{contactTelephone}</td>
                              <td>{(status?.toString()) === "true" ? "N" : "Y"}</td>
                              {( localStorage.getItem('permissions')?.includes('buildingupdate_U') || localStorage.getItem('permissions')?.includes('buildingdelete_D') ) && 
                              <td className="p-0">
                                {localStorage.getItem('permissions')?.includes('buildingupdate_U') &&
                                  <Tooltip 
                                   title={`${status ? "Update" : ""}`} 
                                   color={tooltipColor}
                                   >
                                    <EditOutlined 
                                     onClick={() => { if (!status) { } else { showUpdateSite(item) } }}
                                      style={{ fontSize: "15px", 
                                        color: status ? blueColor : grayColor, 
                                        paddingRight: "0px", 
                                        cursor: status ? "pointer" : "auto" }} 
                                      />
                                  </Tooltip>}
                                {localStorage.getItem('permissions')?.includes('buildingdelete_D') &&
                                  <Tooltip 
                                    title={`${status ? "Deactivate" : ""}`} 
                                    color={tooltipColor}
                                    >
                                    <DeleteOutlined 
                                     onClick={() => { if (!status) { } else { deleteSiteList(buildingId) } }}
                                     style={{ fontSize: "15px", 
                                     color: status ? "red" : grayColor,  
                                     paddingRight: "0px", 
                                     cursor: status ? "pointer" : "auto" }} />
                                  </Tooltip>}
                              </td>
                              }
                            </tr>
                          );
                        })
                      }
                      {
                        tableData?.length == 0 && <tr><td colSpan={10} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div ref={paginationRef} className='text-center'>
                  <PaginationComponent
                    handlePagination={handlePagination}
                    totalLength={totalCount}
                    pageSize={pageSize}
                    current={current}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  );
};

export default SiteMaintenance;