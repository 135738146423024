import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Modal, Form, Input, Button, Select, ConfigProvider, Space } from 'antd';
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import * as roles from '../../settings/roles';
import SessionWarning from '../reusableComponent/sessionWarning';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import DatePicker from 'react-date-picker';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


const userInfo: any = Utility.getUserInfo();
const token: any = userInfo.authToken;



type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    // startDate: string,
    // endDate: string,
    batteryId: string,
    vehicleId: string,
    serialNumber: string,
    batteryRxSim: string,
    pageOffset: number,
    pageLimit: number,
    sortColumns: string,
    sortDirection: string,
    timeZone: string,
    appVersion: string,
    enabled: string,
}

let Body = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    // startDate: "",
    // endDate: "",
    batteryId: "",
    vehicleId: "",
    serialNumber: "",
    batteryRxSim: "",
    pageOffset: 1,
    pageLimit: 10,
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appVersion: "",
    enabled: ""
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    }
    ,
    action: string
    // refresh: number
}

const DropList = [
    { label: "Battery ID" },
    { label: "Vehicle ID" },
    { label: "Battery RX Serial Number" },
    { label: "Battery RX SIM #" },
    { label: "App Version" },
    { label: "Enabled" },
];

const BatteryRXUnits: FC<Props> = ({ globalData, action }) => {
    // const BatteryRXUnits: FC<Props> = ({ globalData, action, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [selector, setSelector] = useState(false);
    const [tableReport, setTableReport] = useState<any[]>([]);
    const [organizations, setOrganizations] = useState<any[]>([]);
    const [locations, setLocationList] = useState<any[]>([]);
    const [buildings, setBuildingList] = useState<any[]>([]);
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])
    const [tableReqBody, setTableReqBody] = useState<reqBody>(Body);
    const [countReqBody, setCountReqBody] = useState<reqBody>(Body)
    const [current, setCurrent] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [count, setCount] = useState<number>(0);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isDeleteOpen, setDelete] = useState<boolean>(false);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [deleteSerialNumber, setDeleteSerialNumber] = useState<string>("");
    const [updateTelError, setUpdateTelError] = useState<string>("")
    const [alert, setAlert] = useState<boolean>(false);
    const [updatereq, setUpdateReq] = useState<any>({
        "organizationID": 0,
        "orgName": "",
        "divisionId": 0,
        "divisionName": "",
        "locationId": 0,
        "locationName": "",
        "buildingId": 0,
        "buildingName": "",
        "protocolVersion": "",
        "bootKernelVersion": "",
        "applicationVersion": "",
        "partNumber": "",
        "serialNumber": "",
        "softwareBuildDate": "",
        "timeZone": "",
        "inServiceDate": "",
        "telephoneNumber": "",
        "enabledState": "",
        "enabledDate": "",
        "isEmeter": "",
        "emeterIdentifier": "",
        "emeterEnabled": "N",
        "dateContractStart": "",
        "dateContractEnd": ""
    })
    const [addreq, setAddReq] = useState<any>({
        organizationID: 0,
        divisionId: 0,
        locationId: 0,
        buildingId: 0,
        isEmeter: "Y",
        emeterIdentifier: "",
        emeterEnabled: "N",
        inServiceDate: "",
        dateContractStart: "",
        dateContractEnd: "",
        timeZone: "",
        telephoneNumber: "",
        partNumber: "",
        protocolVersion: "",
        bootKernelVersion: "",
        applicationVersion: "",
        serialNumber: "",
        softwareBuildDate: "",
        enabledState: "N",
        enabledDate: ""
    });

    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, batteryRxSerialNumber: false, batteryRxSim: false, deviceTimezone: false, organization: false, division: false,
        location: false, building: false, protocolVer: false, bootKerVer: false, appVersion: false, manufDate: false, inSvcDate: false, telephoneNumber: false,
        lastContactDate: false, lastCompleteDate: false, enabled: false, enabledDate: false, isEMeter: false, eMeterIdentifier: false, eMeterSessionId: false,
        dateContractStart: false, dateContractEnd: false, dateUpdated: false
    });

    const [initial, setInitial] = useState<boolean>(true);
    const [timezones, setTimezones] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [titleMsg, setTitleMsg] = useState<string>("")
    const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")
    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        vehicleId: '',
        batteryRxSim: '',
        serialNumber: '',
        appVersion: '',
        enabled: ''
    });
    const [emptyError, setemptyError] = useState<string>("");
    const [companyRules, setCompanyRules] = useState<any[]>([{ required: false }])
    const [locRules, setLocRules] = useState<any[]>([{ required: false }])
    const [siteRules, setSiteRules] = useState<any[]>([{ required: false }])
    const [orgDisabled, setOrgDisabled] = useState<boolean>(false)
    const [companyDisabled, setCompanyDisabled] = useState<boolean>(false)
    const [locDisabled, setLocDisabled] = useState<boolean>(false)
    const [siteDisabled, setSiteDisabled] = useState<boolean>(false)

    const selectorRef = useRef<HTMLDivElement>(null);
    const [form] = Form.useForm()
    const [updateForm] = Form.useForm()
    const [divisions, setDivisions] = useState<any[]>([]);
    const [tableHeight, setTableHeight] = useState<any>(420)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)


    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 108
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 420 ? setTableHeight(420) : setTableHeight(tableHeight)
    };

    async function getOrganization(orgid: any, divid: any, locid: any, siteid: any) {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = {
            "orgId": orgid,
            "divisionId": divid,
            "locationId": locid,
            "buildingId": siteid
        }
        try {
            const response = await service({
                url: constantUrl.api.dashboard.getOrganizations,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            setOrganizations(response);
        } catch (error) {
            console.log("getOrg:", error);
        }
    }

    async function getDivisions(orgid: any) {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        var url = constantUrl.api.location.getDivisions;
        let payload = { orgId: orgid };

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });

            setDivisions(response);
        } catch (error) {
            console.log("getDivisions:", error);
        }
    }

    const getLocations = async (orgid: any, divisionId: any, locationId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = {
            "orgId": orgid,
            "divisionId": divisionId,
            "locationId": locationId,
            "buildingId": buildingId
        }

        try {
            const response = await service({
                url: constantUrl.api.dashboard.getLocations,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload
            })
            setLocationList(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    // useEffect(() => {
    //     if (refresh !== 0) {
    //         getBatteryRXUnits();
    //         getCount()
    //     }
    // }, [refresh])

    useEffect(() => {
        getBatteryRXUnits();
    }, [tableReqBody]);

    useEffect(() => {
        getCount()
    }, [countReqBody])


    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'BATTERY_RX_UNITS',
            reportName: 'BATTERY_RX_UNITS',
            columnSettings: selectorLabel,
            sortColumn: tableReqBody?.sortColumns,
            sortDirection: tableReqBody?.sortDirection
        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    }

    const cancelSelection = () => {
        let columnData = selectorLabel;
        for (let i = 0; i < columnData.length; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'BATTERY_RX_UNITS'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setTableReqBody({ ...tableReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
            setCountReqBody({ ...countReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
            setInitial(false);
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        setInitial(true)
        window.scrollTo(0, 0)
        getReportSettings();
        getTimezones();

    }, [])

    useEffect(() => {
        if (initial === false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setInput("");
            const data = {
                batteryId: '',
                batteryRxSim: '',
                vehicleId: '',
                serialNumber: '',
                appVersion: '',
                enabled: ''
            }
            setSearchObject(data)
            setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
            setCountReqBody({ ...countReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId, globalData?.serialId])

    async function getBatteryRXUnits() {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = tableReqBody

        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();
        if (payload?.serialNumber === "") payload.serialNumber = data?.serialId;

        if (tableReqBody.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.getBatteryRxUnits,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTableReport(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getChargeSummary:", error);
            setIsShowSpinner(false);
        }
    }

    async function getCount() {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = countReqBody

        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();
        if (payload?.serialNumber === "") payload.serialNumber = data?.serialId;

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.getBatteryRxUnitsCount,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setCount(response);
        } catch (error) {
            console.error("getChargeSummaryCount:", error);
        }
    }

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const getDowFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            setIsShowSpinner(true);
            let userInfo: any = Utility.getUserInfo();
            let token: string = userInfo.authToken;
            let payload = {
                orgId: tableReqBody?.orgId,
                divisionId: tableReqBody?.divisionId,
                locationId: tableReqBody?.locationId,
                buildingId: tableReqBody?.buildingId,
                // startDate: tableReqBody?.startDate,
                // endDate: tableReqBody?.endDate,
                batteryId: tableReqBody?.batteryId,
                vehicleId: tableReqBody?.vehicleId,
                serialNumber: tableReqBody?.serialNumber,
                batteryRxSim: tableReqBody?.batteryRxSim,
                appVersion: tableReqBody?.appVersion,
                enabled: tableReqBody?.enabled,
                pageOffset: 1,
                pageLimit: count + 1,
                sortColumns: tableReqBody?.sortColumns,
                sortDirection: tableReqBody?.sortDirection,
                timeZone: tableReqBody?.timeZone

            }
            try {
                const response = await service({
                    url: constantUrl.api.batteryRXUnits.downloadFile + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })

                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'BatteryRXUnits.' + format;
                    a.click();
                    setIsShowSpinner(false);
                }

            } catch (error) {

                console.log(error)
                setIsShowSpinner(false);
            }
        }
        else {
            setAlert(true)
        }
    }

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setTableReqBody({ ...tableReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const showAddUser = () => {
        if (!show) {
            let userType = localStorage?.UserType
            let userInfo: any = Utility.getUserInfo();
            var loginDealerId = userInfo.userId;
            if (roles.PLATFORM_ADMIN === userType) {
                getOrganization(0, 0, 0, 0);
                setCompanyRules([{ required: false }])
            }
            if (roles.DEALER_ADMIN === userType) {
                getOrganization(loginDealerId, 0, 0, 0);
                setCompanyRules([{ required: false }])
            }

            if (roles.COMPANY_ADMIN === userType) {
                setOrgDisabled(true)
                setCompanyDisabled(true)
                setCompanyRules([{ required: true, message: 'Please Select Division' }])
                let organizationID = Number(localStorage.getItem('accountId'))
                let orgName = localStorage.getItem('accountName')
                let divisionId = Number(localStorage.getItem('divisionId'))
                let divisionName = localStorage.getItem('divisionName')
                setAddReq({ ...addreq, organizationID, divisionId })
                form.setFieldsValue({
                    orgName, divisionName, dateContractStart: '', dateContractEnd: "", inServiceDate: "",
                    emeterIdentifier: "", enabledState: "N", isEmeter: "Y", serialNumber: "", telephoneNumber: "",
                    timeZone: ""
                })
                getLocations(organizationID, divisionId, -1, -1)
            }

            if (roles.LOCATION_ADMIN === userType) {
                setOrgDisabled(true)
                setCompanyDisabled(true)
                setLocDisabled(true)
                setCompanyRules([{ required: true, message: 'Please Select Division' }])
                setLocRules([{ required: true, message: 'Please Select Location' }])
                let organizationID = Number(localStorage.getItem('accountId'))
                let orgName = localStorage.getItem('accountName')
                let divisionId = Number(localStorage.getItem('divisionId'))
                let divisionName = localStorage.getItem('divisionName')
                let locationId = Number(localStorage.getItem('locationId'))
                let locationName = localStorage.getItem('locationName')
                setAddReq({ ...addreq, organizationID, divisionId, locationId })

                form.setFieldsValue({
                    orgName, divisionName, locationName, dateContractStart: '', dateContractEnd: "", inServiceDate: "",
                    emeterIdentifier: "", enabledState: "N", isEmeter: "Y", serialNumber: "", telephoneNumber: "",
                    timeZone: ""
                })

                getBuildings(organizationID, divisionId, locationId)
            }
        }
        setShow(!show)
    };

    const onSelectOrg = (organizationID: any) => {
        setAddReq({ ...addreq, organizationID, divisionId: -1, locationId: -1, buildingId: -1 })
        form.setFieldsValue({ divisionName: 'All', locationName: 'All', siteName: 'All' })
        getDivisions(organizationID)
        getLocations(organizationID, -1, -1, -1)
        getBuildings(organizationID, -1, -1)
    }

    const onSelectCompany = (divisionId: any) => {
        setAddReq({ ...addreq, divisionId, locationId: -1, buildingId: -1 })
        form.setFieldsValue({ locationName: 'All', siteName: 'All' })
        getLocations(addreq.organizationID, divisionId, -1, -1)
        getBuildings(addreq.organizationID, divisionId, -1)
    };

    const onSelectLoc = (locationId: any) => {
        setAddReq({ ...addreq, locationId, buildingId: -1 })
        const { organizationID, divisionId } = addreq
        form.setFieldsValue({ siteName: 'All' })
        getBuildings(organizationID, divisionId, locationId)
    };

    const onSelectSite = (buildingId: any) => {
        setAddReq({ ...addreq, buildingId })
    }

    async function onAddFinish(value: any) {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        const { organizationID, divisionId, locationId, buildingId, enabledState, isEmeter } = addreq

        const { orgName, divisionName, locationName, siteName,
            emeterIdentifier, inServiceDate, dateContractStart, dateContractEnd,
            timeZone, telephoneNumber, serialNumber } = value

        let orgId = orgName === 'All' ? -1 : organizationID
        let divId = divisionName === "All" ? -1 : divisionId
        let locId = locationName === 'All' ? -1 : locationId
        let sitId = siteName === 'All' ? -1 : buildingId

        const payload: any = {
            ...addreq,
            organizationID: orgId,
            orgName,
            divisionId: divId,
            divisionName,
            locationId: locId,
            locationName,
            buildingId: sitId,
            siteName,
            isEmeter,
            emeterIdentifier,
            inServiceDate,
            dateContractStart,
            dateContractEnd,
            timeZone,
            telephoneNumber,
            serialNumber,
            enabledState,
        };

        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.addBatteryRxUnits,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload,
            });

            if (response.status === 'SUCCESS') {
                showAddUser();
                setIsOpen(true)
                setTitleMsg("Battery RX Unit added successfully")
                form.resetFields();
                setAddReq({
                    organizationID: 0,
                    divisionId: 0,
                    locationId: 0,
                    buildingId: 0,
                });
                return
            }
            if ((response.status === "FAILURE" || response.errorCode === 1123) || response.errorMessage === 'BMID serial number already exists') {
                form.setFields([{ name: 'serialNumber', errors: [response.errorMessage] }]);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    async function onUpdateFinish(value: any) {
        const payload: any = updatereq;
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.updateBatteryRxUnits,
                method: "PUT",
                headers: { Authorization: 'Bearer ' + token },
                data: payload,
            });

            setUpdateReq({
                organizationID: 0,
                divisionId: 0,
                locationId: 0,
                buildingId: 0,
                isEmeter: "",
                emeterIdentifier: "",
                emeterEnabled: "",
                inServiceDate: "",
                dateContractStart: "",
                dateContractEnd: "",
                timeZone: "",
                telephoneNumber: "",
                partNumber: "",
                protocolVersion: "",
                bootKernelVersion: "",
                applicationVersion: "",
                serialNumber: "",
                softwareBuildDate: "",
                enabledState: "",
                enabledDate: ""
            });
            updateForm.resetFields()

            if (!response) return
            if (response.status === 'SUCCESS') {
                setModalOpen(false)
                setIsOpen(true)
                setTitleMsg("Battery RX Unit updated successfully")
            }

        } catch (error) {
            console.warn(error);
        }
    };

    const cancelUpdate = () => {
        setModalOpen(false)
        updateForm.resetFields()
    }

    const DeleteAlert = (id: string, sno: string) => {
        setDeleteSerialNumber(sno);
        setDelete(true);
    }

    const openUpdate = (item: any) => {
        const { batteryRxSim, building, buildingId, dateContractEnd, dateContractStart, deviceTimezone,
            division, divisionId, emeterIdentifier, enabled, inSvcDate, isEMeter, location, locationId,
            orgId, organization, telephoneNumber } = item

        setModalOpen(true);

        let userType = localStorage?.UserType
        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;
        if (roles.PLATFORM_ADMIN === userType) {
            setCompanyRules([{ required: false }])
            getOrganization(0, 0, 0, 0);
            getDivisions(orgId)
            getLocations(orgId, divisionId, -1, -1)
            getBuildings(orgId, divisionId, locationId)
        }
        if (roles.DEALER_ADMIN === userType) {
            setCompanyRules([{ required: false }])
            getOrganization(loginDealerId, 0, 0, 0);
            getDivisions(orgId)
            getLocations(orgId, divisionId, -1, -1)
            getBuildings(orgId, divisionId, locationId)
        }

        if (roles.COMPANY_ADMIN === userType) {
            setOrgDisabled(true)
            setCompanyDisabled(true)
            setCompanyRules([{ required: true }])
            getLocations(orgId, divisionId, -1, -1)
            getBuildings(orgId, divisionId, locationId)
        }

        if (roles.LOCATION_ADMIN === userType) {
            setOrgDisabled(true)
            setCompanyDisabled(true)
            setLocDisabled(true)
            setCompanyRules([{ required: true }])
            setLocRules([{ required: true }])
            getBuildings(orgId, divisionId, locationId)
        }

        setUpdateReq({
            ...updatereq,
            organizationID: item?.orgId,
            orgName: item?.organization,
            divisionId: item?.divisionId,
            divisionName: item?.division,
            locationId: item?.locationId,
            locationName: item?.location,
            buildingId: item?.buildingId,
            buildingName: item?.building,
            protocolVersion: item?.protocolVer,
            bootKernelVersion: item?.bootKerVer,
            applicationVersion: item?.appVersion,
            partNumber: item?.batteryRxSim,
            serialNumber: item?.batteryRxSerialNumber,
            softwareBuildDate: "",
            timeZone: item?.deviceTimezone,
            inServiceDate: item?.inSvcDate,
            telephoneNumber: item?.telephoneNumber,
            enabledState: item?.enabled,
            enabledDate: item?.enabledDate,
            isEmeter: item?.isEMeter,
            emeterIdentifier: item?.emeterIdentifier,
            emeterEnabled: item?.enabled,
            dateContractStart: item?.dateContractStart,
            dateContractEnd: item?.dateContractEnd
        });

        updateForm.setFieldsValue({
            orgName: organization,
            divisionName: division,
            locationName: location,
            buildingName: building,
            partNumber: batteryRxSim,
            timeZone: deviceTimezone,
            telephoneNumber: telephoneNumber,
            inServiceDate: inSvcDate,
            dateContractStart: dateContractStart,
            dateContractEnd: dateContractEnd,
            enabledState: enabled,
            emeterIdentifier: emeterIdentifier,
            isEmeter: isEMeter
        })
    }

    const deleteRXUnit = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.delete + deleteSerialNumber,
                method: "DELETE",
                headers: { Authorization: 'Bearer ' + token }
            })

            if (response) {
                setDelete(false);
                setIsOpen(true)
                setTitleMsg("Battery RX Unit deleted successfully")
            }

        } catch (error) {
            console.log("locations:", error)
        }
    }


    const getBuildings = async (orgId: any, divisionId: any, locationId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = { orgId, divisionId, locationId }

        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getSites,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload
            })

            setBuildingList(response)
        } catch (error) {
            console.log("sitesdropdownlist:", error)
        }
    }

    const updateOrg = (orgId: number) => {
        const u_org = organizations.find((item: any) => item.orgId === orgId)
        u_org && setUpdateReq({ ...updatereq, organizationID: u_org.orgId, orgName: u_org.orgName, divisionId: -1, divisionName: 'All', locationId: -1, locationName: 'All', buildingName: 'All', buildingId: -1 });
        u_org && updateForm.setFieldsValue({ orgName: u_org.orgName, divisionName: 'All', locationName: 'All', buildingName: 'All' })
        getDivisions(orgId)
        getLocations(orgId, -1, -1, -1)
        getBuildings(orgId, -1, -1)
    };


    const updateCompany = (divisionId: any) => {
        const u_division = divisions.find((item: any) => item.divisionId === divisionId)
        if (divisionId === -1) {
            setUpdateReq({ ...updatereq, divisionId: -1, divisionName: 'All', locationId: -1, locationName: 'All', buildingName: 'All', buildingId: -1 });
            updateForm.setFieldsValue({ divisionName: 'All', locationName: 'All', buildingName: 'All' })
        } else {
            u_division && setUpdateReq({ ...updatereq, divisionId: u_division['divisionId'], locationId: -1, locationName: 'All', buildingName: 'All', buildingId: -1 });
            u_division && updateForm.setFieldsValue({ divisionName: u_division['divisionName'], locationName: 'All', buildingName: 'All' })
        }
        getLocations(updatereq.organizationID, divisionId, -1, -1)
        getBuildings(updatereq.organizationID, divisionId, -1)
    };

    const updateLoc = (locationId: number) => {
        const { organizationID, divisionId } = updatereq
        const u_locations = locations.find((item: any) => item.locId === locationId)
        if (locationId === -1) {
            setUpdateReq({ ...updatereq, locationId: -1, locationName: 'All', buildingName: 'All', buildingId: -1 });
            updateForm.setFieldsValue({ locationName: 'All', buildingName: 'All' })
        } else {
            u_locations && setUpdateReq({ ...updatereq, locationId: u_locations['locId'], locationName: 'All', buildingName: 'All', buildingId: -1 });
            u_locations && updateForm.setFieldsValue({ locationName: u_locations['locationName'], buildingName: 'All' })
        }
        getBuildings(organizationID, divisionId, locationId)
    };

    const updateSite = (buildingId: number) => {
        const u_buildings = buildings.find((item) => item.id === buildingId)
        if (buildingId === -1) {
            setUpdateReq({ ...updatereq, buildingName: 'All', buildingId: -1 });
            updateForm.setFieldsValue({ buildingName: 'All' })
        } else {
            u_buildings && setUpdateReq({ ...updatereq, buildingName: u_buildings['buildingName'], buildingId: u_buildings['id'] });
            u_buildings && updateForm.setFieldsValue({ buildingName: u_buildings['buildingName'] })
        }

    };

    const getTimezones = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.getTimezones,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token },
            })

            setTimezones(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]

        for (let sortLabel in sort) {
            switch (id) {
                case sortLabel:
                    if (sort[sortLabel]) {
                        setTableReqBody({ ...tableReqBody, sortColumns: sortLabel, sortDirection: "ASC", pageOffset: 1 })
                        setCurrent(1)
                        setSort({ ...sort, [id]: getIdboolean })
                    } else {
                        setTableReqBody({ ...tableReqBody, sortColumns: sortLabel, sortDirection: "DESC", pageOffset: 1 })
                        setCurrent(1)
                        setSort({ ...sort, [id]: getIdboolean })
                    }
            }
        }
    };

    const inServiceDateUpdate = (date: any) => {
        setUpdateReq({ ...updatereq, inServiceDate: date })
    };

    const emeterEnabledUpdate = (valk: any) => {
        setUpdateReq({ ...updatereq, enabledState: valk })
    };

    const updateTelNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        let telPattern = /^-?\d*(\.\d*)?$/
        let test1 = telPattern.test(event.target.value)
        if (!test1) {
            setUpdateTelError("Telephone No must be specified and be 10 digits in length")
            return
        } else {
            setUpdateTelError("")
            setUpdateReq({ ...updatereq, telephoneNumber: event.target.value })
        }
    };

    const toggleModal = () => {
        setIsOpen(false)
        switch (titleMsg) {
            case 'Battery RX Unit added successfully':
                // getBatteryRXUnits();
                // getCount();
                break;
            case 'Battery RX Unit updated successfully':
                //getBatteryRXUnits();
                break;
            case 'Battery RX Unit deleted successfully':
                //getBatteryRXUnits();
                //getCount();
                break;
        }
        window.location.reload();
    };

    //dropdown for search filter
    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        const data = {
            batteryId: '',
            batteryRxSim: '',
            vehicleId: '',
            serialNumber: '',
            appVersion: '',
            enabled: ''
        }
        setSearchObject(data)
        if (inputvalue) {
            setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1 })
            setCountReqBody({ ...countReqBody, ...data, pageOffset: 1 })
        }

    };

    const handleSearch = () => {
        const { batteryId, vehicleId, batteryRxSim, serialNumber, appVersion, enabled } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery ID':
                if (!batteryId) {
                    setemptyError("Please Enter Battery ID")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery RX SIM #':
                if (!serialNumber) {
                    setemptyError("Please Enter Battery Rx SIM")
                    return
                } else {
                    obj.serialNumber = inputvalue.trim();
                }
                break;
            case 'Battery RX Serial Number':
                if (!batteryRxSim) {
                    setemptyError("Please Enter Battery RX Serial Number")
                    return
                } else {
                    obj.batteryRxSim = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            case 'App Version':
                if (!appVersion) {
                    setemptyError("Please Enter App Version")
                    return
                } else {
                    obj.appVersion = inputvalue.trim();
                }
                break;
            case 'Enabled':
                if (!enabled) {
                    setemptyError("Please Enter Enabled")
                    return
                } else {
                    obj.enabled = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, ...obj, pageOffset: 1 })
    };

    //Search Find Click
    const onChangePayload = (value: any) => {
        let obj = { ...searchObject };

        if (dropdownvalue === "Battery ID") {
            obj.batteryId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery RX SIM #") {
            obj.serialNumber = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery RX Serial Number") {
            obj.batteryRxSim = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "App Version") {
            obj.appVersion = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Enabled") {
            obj.enabled = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        }
        setemptyError("")
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery ID':
                obj.batteryId = value;
                break;
            case 'Battery RX SIM #':
                obj.serialNumber = value;
                break;
            case 'Battery RX Serial Number':
                obj.batteryRxSim = value;
                break;
            case 'App Version':
                obj.appVersion = value;
                break;
            case 'Enabled':
                obj.enabled = value;
                break;
        }
        setSearchObject({ ...obj })
        setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, ...obj, pageOffset: 1 })
    };

    const callCountAndReport = (obj: any) => {
        setTableReqBody({ ...tableReqBody, ...obj })
        setCountReqBody({ ...countReqBody, ...obj })
    };

    return (<>
        <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
        <div className='container-fluid px-0 mb-3 marginT'>
            {localStorage.getItem('permissions')?.includes('OBatteryRxUnits_W') ? <div className=" row nav-filter align-items-center">
                <div className="col-lg-3 col-md-4 col-sm-12  tab_content-battery">
                    <span onClick={showAddUser} className="d-flex align-items-center  ">
                        {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />} <span className="pl-2 paddingT"> Add a New Battery RX Unit</span> </span>
                </div>
            </div> : <></>}

            {show && <div className={show ? "show-user add-user" : 'hide-user add-user'}>
                <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                    <Form layout='vertical' form={form} onFinish={onAddFinish}>
                        <div className="row">
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='orgName'
                                    label="Organization"
                                    rules={[{ required: true, message: 'Please Select Organization' }]}
                                >
                                    <Select placeholder="Select Organization"
                                        //value={addreq?.organizationID} 
                                        disabled={orgDisabled}
                                        onSelect={(value) => onSelectOrg(value)}
                                        dropdownStyle={{ zIndex: 900 }}
                                    >
                                        {organizations && organizations.map((item: any, index: any) => {
                                            return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='divisionName'
                                    label="Division"
                                    rules={companyRules}
                                >
                                    <Select placeholder="Select Division"
                                        disabled={companyDisabled}
                                        defaultValue={'All'}
                                        onSelect={(value) => onSelectCompany(value)}
                                        dropdownStyle={{ zIndex: 900 }}
                                    >    <Select.Option value={-1}>All</Select.Option>
                                        {divisions && divisions.map((item: any, index: any) => {
                                            return <Select.Option key={index} value={item.divisionId}>{item.divisionName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='locationName'
                                    label="Location"
                                    rules={locRules}
                                >
                                    <Select placeholder="Select Location"
                                        disabled={locDisabled}
                                        defaultValue={'All'}
                                        onSelect={(value) => onSelectLoc(value)}
                                        dropdownStyle={{ zIndex: 900 }}
                                    >   <Select.Option value={-1}>All</Select.Option>
                                        {locations && locations.map((item: any, index: any) => {
                                            return <Select.Option key={index} value={item.locId}>{item.locationName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='siteName'
                                    label="Site"
                                    rules={siteRules}
                                >
                                    <Select placeholder="Select Site"
                                        disabled={siteDisabled}
                                        defaultValue={'All'}
                                        onSelect={(value) => onSelectSite(value)}
                                        dropdownStyle={{ zIndex: 900 }}
                                    >
                                        <Select.Option value={-1}>All</Select.Option>
                                        {buildings && buildings.map((item: any, index: any) => {
                                            return <Select.Option key={index} value={item.id}>{item.buildingName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    name='serialNumber'
                                    label="Battery RX SIM #"
                                    rules={[{ required: true, message: 'Please Enter Battery RX SIM #' }]}
                                    style={{ marginBottom: "5px" }}
                                    required
                                >
                                    <Input placeholder="Enter Battery RX SIM #" onChange={(e) => { setAddReq({ ...addreq, serialNumber: e.target.value }) }} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='timeZone'
                                    label="Device Timezone"
                                // rules={[{ required: true, message: 'Please Select Device Timezone' }]}
                                >
                                    <Select placeholder="Select Device Timezone" value={addreq?.timeZone} onSelect={(value) => { setAddReq({ ...addreq, timeZone: value }) }} dropdownStyle={{ zIndex: 900 }}>
                                        {timezones && timezones.map((item: any, index: any) => {
                                            return <Select.Option value={item?.timeZone} key={index}>{"(" + item?.timeZone + ") " + item?.mstimeZoneID}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    name='telephoneNumber'
                                    label="Telephone Number"
                                    rules={[{ required: true, message: 'Please Enter Telephone Number', whitespace: true },
                                    { pattern: new RegExp(/^[0-9]+$/), message: 'Only Numbers are allowed', }]}
                                    style={{ marginBottom: "5px" }}
                                >
                                    <Input type="tel" maxLength={10} placeholder="Enter Telephone Number" onChange={(e) => { setAddReq({ ...addreq, telephoneNumber: e.target.value }) }} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    name='inServiceDate'
                                    label="In Service Date"
                                    // rules={[{ required: true, message: 'Please Select In Service Date' }]}
                                    style={{ marginBottom: "5px" }}
                                >
                                    <DatePicker onChange={(date: any) => setAddReq({ ...addreq, inServiceDate: date })} className="w-100" value={addreq.inServiceDate} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    name='dateContractStart'
                                    label="Date Contract Start"
                                    // rules={[{ required: true, message: 'Please Select Date Contract Start' }]}
                                    style={{ marginBottom: "5px" }}
                                >
                                    <DatePicker onChange={(date: any) => setAddReq({ ...addreq, dateContractStart: date })} className="w-100" value={addreq.dateContractStart} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    name='dateContractEnd'
                                    label="Date Contract End"
                                    // rules={[{ required: true, message: 'Please Select Date Contract End' }]}
                                    style={{ marginBottom: "5px" }}
                                // required
                                >
                                    <DatePicker onChange={(date: any) => setAddReq({ ...addreq, dateContractEnd: date })} className="w-100" value={addreq.dateContractEnd} />
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='enabledState'
                                    label="Enabled"
                                // rules={[{ required: true, message: 'Please Select Status' }]}
                                >
                                    <Select placeholder="Select Status" defaultValue={addreq.enabledState} onSelect={(value) => { setAddReq({ ...addreq, enabledState: value }) }} dropdownStyle={{ zIndex: 900 }}>
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item style={{ marginBottom: "5px" }}
                                    name='isEmeter'
                                    label="Unit used as E Meter"
                                // rules={[{ required: true, message: 'Please Select E Meter Status' }]}
                                >
                                    <Select placeholder="Select E Meter Status" defaultValue={addreq.isEmeter} onSelect={(value) => { setAddReq({ ...addreq, isEmeter: value }) }} dropdownStyle={{ zIndex: 900 }}>
                                        <Select.Option value="Y">Yes</Select.Option>
                                        <Select.Option value="N">No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    name='emeterIdentifier'
                                    label="E Meter Identifier"
                                    // rules={[{ required: true, message: 'Please Enter E Meter Identifier' }]}
                                    style={{ marginBottom: "5px" }}
                                // required
                                >
                                    <Input placeholder="Enter E Meter Identifier" value={addreq?.emeterIdentifier} onChange={(e) => { setAddReq({ ...addreq, emeterIdentifier: e.target.value }) }} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='d-flex' style={{ justifyContent: "end" }}>
                            <div>
                                <Form.Item style={{ marginBottom: "0px" }}>
                                    <Button onClick={showAddUser} style={{ background: '#004f9f', borderBlockColor: "#004f9f", color: "white" }}>Cancel</Button>
                                </Form.Item>
                            </div>
                            <div className='pl-3'>
                                <Form.Item style={{ marginBottom: "0px" }}>
                                    <Button style={{ background: '#004f9f', borderBlockColor: "#004f9f", color: "white" }} htmlType='submit'>Add</Button>
                                    {/* onClick={onAddFinish} */}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </ConfigProvider>
            </div>}
        </div>

        <div>
            {/* Update form */}
            <ConfigProvider
                theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                <Modal
                    footer={null}
                    onCancel={() => { setModalOpen(false) }}
                    className='update-users'
                    title="Update Battery RX Units"
                    centered open={isModalOpen}>
                    <Form className='user-form'
                        style={{ padding: "0" }}
                        layout='vertical'
                        form={updateForm}
                        onFinish={onUpdateFinish}
                    >
                        <div>
                            <div className="row">
                                <div className="col-6 pb-2 font-color">Battery Rx SIM #</div>
                                <div className="col-6 pb-2 font-color">{updatereq?.serialNumber}</div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Organization'
                                        name='orgName'
                                        rules={[{ required: true, message: 'Please Select Organization' }]}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Select
                                            disabled={orgDisabled}
                                            onSelect={(valk) => { updateOrg(valk) }}
                                            placeholder="Select Organization"
                                            value={updatereq?.orgName}
                                        >
                                            {organizations && organizations.map((item: any, index: any) => {
                                                return <Select.Option value={item?.orgId} key={index}>{item?.orgName}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item style={{ marginBottom: "5px" }}
                                        name='divisionName'
                                        label="Division"
                                        rules={companyRules}
                                    >
                                        <Select placeholder="Select Division" value={updatereq?.divisionName}
                                            disabled={companyDisabled}
                                            onSelect={(value) => updateCompany(value)}
                                        >
                                            <Select.Option value={-1}>All</Select.Option>
                                            {divisions && divisions.map((item: any, index: any) => {
                                                return <Select.Option key={index} value={item.divisionId}>{item.divisionName}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Location'
                                        name='locationName'
                                        rules={locRules}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Select
                                            disabled={locDisabled}
                                            onSelect={(valk) => { updateLoc(valk) }}
                                            placeholder="Select Location"
                                            value={updatereq?.locationName}
                                        >
                                            <Select.Option value={-1}>All</Select.Option>
                                            {locations && locations.map((item: any, index: any) => {
                                                return <Select.Option value={item?.locId} key={index}>{item?.locationName}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Site'
                                        name='buildingName'
                                        rules={siteRules}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Select
                                            disabled={siteDisabled}
                                            onSelect={(valk) => { updateSite(valk) }}
                                            placeholder="Select Site"
                                            value={updatereq?.buildingName}
                                        >
                                            <Select.Option value={-1}>All</Select.Option>
                                            {buildings && buildings.map((item: any, index: any) => {
                                                return <Select.Option value={item?.id} key={index}>{item?.buildingName}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Serial Number"
                                        name='partNumber'
                                        rules={[{ required: true, message: 'Please Enter Serial Number' }]}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Input
                                            placeholder="Serial Number"
                                            value={updatereq?.partNumber}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUpdateReq({ ...updatereq, partNumber: event.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Device Timezone'
                                        name='timeZone'
                                    >
                                        <Select
                                            size={'middle'}
                                            onSelect={(valk) => { setUpdateReq({ ...updatereq, timeZone: valk }); }}
                                            placeholder="Select Device Timezone"
                                        >
                                            {timezones && timezones.map((item: any, index: any) => {
                                                return <Select.Option value={item?.timeZone} key={index}>{"(" + item?.timeZone + ") " + item?.mstimeZoneID}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Telephone Number'
                                        name='telephoneNumber'
                                        style={{ marginBottom: "5px" }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Telephone Number',
                                                whitespace: true, // Validate that the field is not just spaces
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]+$/),
                                                message: 'Only Numbers are allowed',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter Telephone No"
                                            type="tel"
                                            maxLength={10}
                                            value={updatereq?.telephoneNumber}
                                            onChange={updateTelNumber}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='In Service Date'
                                        name='inServiceDate'
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <DatePicker
                                            className='w-100'
                                            onChange={inServiceDateUpdate} value={updatereq?.inServiceDate} />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Date Contract Start'
                                        name='dateContractStart'
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <DatePicker className='w-100' onChange={(date: any) => setUpdateReq({ ...updatereq, dateContractStart: date })} value={updatereq?.dateContractStart} />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Date Contract End'
                                        name='dateContractEnd'
                                    >
                                        <DatePicker className='w-100' onChange={(date: any) => setUpdateReq({ ...updatereq, dateContractEnd: date })} value={updatereq?.dateContractEnd} />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Enabled'
                                        name='enabledState'
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Select
                                            value={updatereq?.enabledState === 'Y' ? 'Yes' : 'No'}
                                            onSelect={emeterEnabledUpdate}
                                            placeholder="Select Status"
                                        >
                                            <Select.Option value={"Y"}>Yes</Select.Option>
                                            <Select.Option value={"N"}>No</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='Unit is E-Meter'
                                        name='isEmeter'
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Select
                                            placeholder='Select Unit'
                                            value={updatereq?.isEmeter === 'Y' ? 'Yes' : 'No'}
                                            onSelect={(valk) => { setUpdateReq({ ...updatereq, isEmeter: valk }); }}
                                        >
                                            <Select.Option value={"Y"}>Yes</Select.Option>
                                            <Select.Option value={"N"}>No</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label='E-Meter Identifier'
                                        name='emeterIdentifier'
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <Input
                                            placeholder="Enter E-Meter Identifier"
                                            value={updatereq?.emeterIdentifier}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUpdateReq({ ...updatereq, emeterIdentifier: event.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            <Form.Item style={{ marginBottom: "0px" }}>
                                                <Button
                                                    onClick={cancelUpdate}
                                                    style={{ color: "#004f9f", }}>
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </div>
                                        <div className="pl-3">
                                            <Form.Item style={{ marginBottom: "0px" }}>
                                                <Button
                                                    style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                                                    htmlType="submit">
                                                    Update
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>
            {/* Update form */}
            <ConfigProvider
                theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="back" onClick={() => setDelete(false)}>Cancel</Button>,
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={deleteRXUnit}>OK</Button>
                    ]}
                    onCancel={() => setDelete(false)}
                    title="Delete Battery RX Units"
                    centered open={isDeleteOpen}>
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className='row'>Are you sure you want to delete this Battery Rx unit?</div>
                    </Form>
                </Modal>
            </ConfigProvider>
        </div>

        <div ref={filtersRef} className="posi_bmid_search_container">
            <div className="col-md-6 px-0 drop_search-main">
                <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                    <div className='ant-design-select'>
                        <Select
                            value={dropdownvalue}
                            size={'middle'}
                            onChange={onChangedropdown}
                            className='drop-filter'
                            dropdownStyle={{ zIndex: 900 }}
                        >
                            {DropList?.map((item: any, index: any) => {
                                return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                            })}
                        </Select>
                    </div>
                    <div>
                        <Space direction="vertical" size="middle" className='search-input'>
                            <Space.Compact className='posi_bmid_search_width'>
                                <Input
                                    allowClear
                                    placeholder={placeholder}
                                    value={inputvalue}
                                    onPressEnter={onPressEnter}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                />
                                <Button type="primary" onClick={handleSearch}>Find</Button>
                            </Space.Compact>
                        </Space>
                        <div className="errorColor">{emptyError}</div>
                    </div>
                </ConfigProvider>
            </div>
            <div className="column-selector-btn">
                <button onClick={selectColumn} type="button" className="btn btn-light selector-btn">Column Selector</button>
            </div>
        </div>

        <div className="main-panel pt-3 position-relative">
            <div className="ChartPanel panelShadow bg-white">
                <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                    <div className='ml-auto mr-auto'>Battery RX Units</div>
                    <div className='show-entries'>
                        <ShowEntries getPageSize={onSelect} />
                    </div>
                </div>
                <div id="table-container">
                    <div style={{ height: tableHeight + 'px' }} className="batteryRx overflow-auto scrollbar">
                        <table className="billable width100">
                            <thead className='tableHeader'>
                                <tr className="billable-head">
                                    <th>{localStorage.getItem('permissions')?.includes('OBatteryRxUnits_U') && <span>-</span>}</th>
                                    <th>{localStorage.getItem('permissions')?.includes('OBatteryRxUnits_D') && <span>-</span>}</th>
                                    {column?.[0]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[0]?.['columnName']}</span>
                                            {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[1]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[1]?.['columnName']}</span>
                                            {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[2]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('batteryRxSerialNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[2]?.['columnName']}</span>
                                            {sort.batteryRxSerialNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[3]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('batteryRxSim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[3]?.['columnName']}</span>
                                            {sort.batteryRxSim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[4]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('deviceTimezone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[4]?.['columnName']}</span>
                                            {sort.deviceTimezone ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[5]?.['enabled'] ? <th>
                                        <span >{column?.[5]?.['columnName']}</span>
                                    </th> : ""}
                                    {column?.[6]?.['enabled'] ? <th>
                                        <span >{column?.[6]?.['columnName']}</span>
                                    </th> : ""}
                                    {column?.[7]?.['enabled'] ? <th>
                                        <span >{column?.[7]?.['columnName']}</span>
                                    </th> : ""}
                                    {column?.[8]?.['enabled'] ? <th>
                                        <span >{column?.[8]?.['columnName']}</span>
                                    </th> : ""}
                                    {column?.[9]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('protocolVer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[9]?.['columnName']}</span>
                                            {sort.protocolVer ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[10]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('bootKerVer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[10]?.['columnName']}</span>
                                            {sort.bootKerVer ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[11]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('appVersion')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[11]?.['columnName']}</span>
                                            {sort.appVersion ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[12]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('manufDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[12]?.['columnName']}</span>
                                            {sort.manufDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[13]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('inSvcDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[13]?.['columnName']}</span>
                                            {sort.inSvcDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[14]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('telephoneNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[14]?.['columnName']}</span>
                                            {sort.telephoneNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[15]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('lastContactDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[15]?.['columnName']}</span>
                                            {sort.lastContactDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[16]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('lastCompleteDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[16]?.['columnName']}</span>
                                            {sort.lastCompleteDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[17]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('enabled')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[17]?.['columnName']}</span>
                                            {sort.enabled ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[18]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('enabledDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[18]?.['columnName']}</span>
                                            {sort.enabledDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[19]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('isEMeter')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[19]?.['columnName']}</span>
                                            {sort.isEMeter ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[20]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('eMeterIdentifier')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[20]?.['columnName']}</span>
                                            {sort.eMeterIdentifier ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[21]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('eMeterSessionId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[21]?.['columnName']}</span>
                                            {sort.eMeterSessionId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[22]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('dateContractStart')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[22]?.['columnName']}</span>
                                            {sort.dateContractStart ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[23]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('dateContractEnd')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[23]?.['columnName']}</span>
                                            {sort.dateContractEnd ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                    {column?.[24]?.['enabled'] ? <th>
                                        <div onClick={() => sortBy('dateUpdated')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                            <span >{column?.[24]?.['columnName']}</span>
                                            {sort.dateUpdated ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                        </div>
                                    </th> : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableReport?.map((report, index) => {
                                        return <tr key={index + 1}>
                                            <td>
                                                {
                                                    localStorage.getItem('permissions')?.includes('OBatteryRxUnits_U') && <span onClick={() => { openUpdate(report) }}>
                                                        <a href="#" onClick={(e) => e.preventDefault()}>Update</a>
                                                    </span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    localStorage.getItem('permissions')?.includes('OBatteryRxUnits_D') && <span onClick={() => DeleteAlert(report?.id, report?.batteryRxSerialNumber)}>
                                                        <a href="#" onClick={(e) => e.preventDefault()}>Delete</a>
                                                    </span>
                                                }
                                            </td>
                                            {column?.[0]?.['enabled'] ? <td>{report?.batteryId}</td> : ""}
                                            {column?.[1]?.['enabled'] ? <td>{report?.vehicleId}</td> : ""}
                                            {column?.[2]?.['enabled'] ? <td>{report?.batteryRxSim}</td> : ""}
                                            {column?.[3]?.['enabled'] ? <td>{report?.batteryRxSerialNumber}</td> : ""}
                                            {column?.[4]?.['enabled'] ? <td>{report?.deviceTimezone}</td> : ""}
                                            {column?.[5]?.['enabled'] ? <td>{report?.organization}</td> : ""}
                                            {column?.[6]?.['enabled'] ? <td>{report?.division}</td> : ""}
                                            {column?.[7]?.['enabled'] ? <td>{report?.location}</td> : ""}
                                            {column?.[8]?.['enabled'] ? <td>{report?.building}</td> : ""}
                                            {column?.[9]?.['enabled'] ? <td>{report?.protocolVer}</td> : ""}
                                            {column?.[10]?.['enabled'] ? <td>{report?.bootKerVer}</td> : ""}
                                            {column?.[11]?.['enabled'] ? <td>{report?.appVersion}</td> : ""}
                                            {column?.[12]?.['enabled'] ? <td>{report?.formatManufDate}</td> : ""}
                                            {column?.[13]?.['enabled'] ? <td>{report?.formatInSvcDate}</td> : ""}
                                            {column?.[14]?.['enabled'] ? <td>{report?.telephoneNumber}</td> : ""}
                                            {column?.[15]?.['enabled'] ? <td>{report?.formatLastContactDate}</td> : ""}
                                            {column?.[16]?.['enabled'] ? <td>{report?.formatLastCompleteDate}</td> : ""}
                                            {column?.[17]?.['enabled'] ? <td>{report?.enabled}</td> : ""}
                                            {column?.[18]?.['enabled'] ? <td>{report?.formatEnabledDate}</td> : ""}
                                            {column?.[19]?.['enabled'] ? <td>{report?.isEMeter}</td> : ""}
                                            {column?.[20]?.['enabled'] ? <td>{report?.emeterIdentifier}</td> : ""}
                                            {column?.[21]?.['enabled'] ? <td>{report?.emeterSessionId}</td> : ""}
                                            {column?.[22]?.['enabled'] ? <td>{report?.formatDateContractStart}</td> : ""}
                                            {column?.[23]?.['enabled'] ? <td>{report?.formatDateContractEnd}</td> : ""}
                                            {column?.[24]?.['enabled'] ? <td>{report?.formatDateUpdated}</td> : ""}
                                        </tr>
                                    })
                                }
                                {tableReport?.length === 0 && <tr><td colSpan={26} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>}
                            </tbody>
                        </table>
                    </div>
                    <div ref={paginationRef}>
                        <PaginationComponent
                            handlePagination={handlePagination}
                            totalLength={count}
                            pageSize={pageSize}
                            current={current}
                        />
                    </div>
                </div>

            </div>
            <div ref={selectorRef} className="column-selector selector ">
                <div className="column-title">Column Selector</div>
                <div className="slector-body">
                    <label>
                        <input
                            checked={clearAll}
                            value="clearAll"
                            type="checkbox"
                            aria-label="checkbox"
                            onChange={clearall}
                        />
                        <span></span>
                    </label>
                    <p>Clear All</p>

                    <label>
                        <input
                            checked={selectAll}
                            value="selectAll"
                            type="checkbox"
                            aria-label="checkbox"
                            onChange={selectall}
                        />
                        <span></span>
                    </label>
                    <p>Select All</p>
                </div>
                <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                    {selectorLabel?.map((item: any, index: any) => {
                        return (
                            <div key={index + '1'} className="slector-body">
                                <label>
                                    <input
                                        name={index}
                                        onChange={onchangeColumn}
                                        checked={item['enabled']}
                                        value={item.value}
                                        type="checkbox"
                                        aria-label="checkbox"
                                    />
                                    <span></span>
                                </label>
                                <p>{item['columnName']}</p>
                            </div>
                        )
                    })}
                </div>
                <div className="m-flex">
                    <button onClick={cancelSelection} className="cancel">
                        Cancel
                    </button>
                    <button onClick={applyChanges} className="apply-changes mr-3">
                        Apply Changes
                    </button>
                </div>
            </div>
        </div>
        <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
            <Modal open={isOpen} closable={false} centered title={titleMsg}
                footer={[<Button style={{ background: '#004f9f', color: "white" }} onClick={toggleModal}>OK</Button>]}
            ></Modal>
        </ConfigProvider>
        <Modal
            open={alert}
            closable={false}
            centered title={"Atleast Select 1 Column"}
            footer={[
                <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
            ]}
        ></Modal>
        <SessionWarning />
    </>)
}

export default BatteryRXUnits;