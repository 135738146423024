import React, { FC,useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import PosinetHeader from '../layout/header';
import PosinetMenu from '../layout/menu';
import CommonHeader from '../components/headerComponent/commonHeader';

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    setGlobalData: (key: string, value: any) => void,
    setAction: (value: string) => void,
    doRefresh: (value: number) => void,
    setEmail:()=>void
    dealer: string,
    company: string,
    loc: string,
    site: string
}

const Posinet: FC<Props> = ({ globalData, setGlobalData, setAction, doRefresh, dealer, company, loc, site,setEmail }) => {
    const location = useLocation();
    const [currentMenu,setCurrentMenu] = useState("")

    // taking the side refrence and sending to CommonHeader component
    const getSideMenu = (getMenu:string)=>{
          setCurrentMenu(getMenu)
    }
    return (
        <div>
            <PosinetMenu getSideMenu={getSideMenu}/>
            {
                (location?.pathname === "/reports" || location?.pathname === "/logs" || location?.pathname === "/custom-reports") ?
                    <PosinetHeader />
                    :
                    location?.pathname == "/user-account-management" ?
                    <PosinetHeader 
                     company={<div>User Account Management</div>}>
                    </PosinetHeader>
                    :
                    location?.pathname == "/battery-rxconfig" ?
                    <PosinetHeader 
                     company={<div style={{ height: "30px" }}></div>}>
                    </PosinetHeader>
                    :
                    <PosinetHeader 
                     company={<CommonHeader dealer={dealer} 
                     company={company} loc={loc} site={site} 
                     globalData={globalData} 
                     setGlobalData={setGlobalData} 
                     setAction={setAction} 
                     doRefresh={doRefresh} 
                     sendEmail={setEmail}
                     currentMenu={currentMenu}
                     />} />
            }
            {/* <div className="container-fluid page-body-wrapper posi_margin_top_mobile"> */}
            <div className="container-fluid page-body-wrapper">
                <Outlet />
            </div>
        </div>
    )
}

export default Posinet;