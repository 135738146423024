import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/dateNewFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, subDays, parseISO } from 'date-fns';
import { Input, ConfigProvider, Select, Space, Button, Modal } from "antd";
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";


const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

type reqBody = {
  orgId: number,
  divisionId: number,
  locationId: number,
  buildingId: number,
  startDate: string,
  endDate: string,
  batteryId: string,
  vehicleId: string,
  serialNumber: string,
  batteryRxSim: string,
  appVersion: string,
  enabled: string,
  pageOffset: number,
  pageLimit: number,
  sortColumns: string,
  sortDirection: string,
  timeZone: string
}

let Body = {
  orgId: 0,
  divisionId: -1,
  locationId: -1,
  buildingId: -1,
  startDate: sevenDaysAgo,
  endDate: today,
  batteryId: "",
  vehicleId: "",
  serialNumber: "",
  batteryRxSim: "",
  appVersion: "",
  enabled: "",
  pageOffset: 1,
  pageLimit: 10,
  sortColumns: "",
  sortDirection: "",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh: number
}

const FirmHistoryReport: FC<Props> = ({ globalData, action }) => {
  // const FirmHistoryReport: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [selector, setSelector] = useState(false)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [column, setColumn] = useState<any[]>([])
  const [selectorLabel, setSelectorLabel] = useState<any[]>([])
  const [sort, setSort] = useState<any>({
    batteryId: false, vehicleId: false, rxSim: false, oldApp: false, NewApp: false, imgFile: false,
    terminated: false, completed: false, bytesSent: false, bytesPerSec: false, totalRetries: false, imgName: false, startDate: false, finishDate: false,
    org: false, div: false, loc: false, site: false
  })
  const [tableReport, setTableReport] = useState<any[]>([])
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [exportReqBody, setExportReqBody] = useState<reqBody>(Body)
  const [minIndex, setMindex] = useState<number>(0)
  const [maxIndex, setMaxindex] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [countReq, setCountReq] = useState<reqBody>(Body)
  const [count, setCount] = useState<number>(0)
  const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const [emptyError, setemptyError] = useState<string>("");
  const [alert, setAlert] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>({
    batteryId: '',
    vehicleId: '',
    batteryRxSim: ''
  })

  const DropList = [
    { label: "Battery ID" },
    { label: "Vehicle ID" },
    { label: "Battery Rx Serial Number" }

  ];

  const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
  const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
  const [inputvalue, setInput] = useState<any>("")

  const onChangedropdown = (value: string) => {
    setemptyError("")
    setDropDownValue(value);
    setPlaceholder(`Search ${value}`)
    setInput("");
    const data = {
      batteryId: '',
      vehicleId: '',
      batteryRxSim: ''
    }
    setSearchObject(data)
    if (inputvalue) {
      setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1 })
      setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1 })
      setCountReq({ ...countReq, ...data, pageOffset: 1 })
    }

  }

  const [tableHeight, setTableHeight] = useState<any>(445)
  const filtersRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const dateValue = [
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 14 Days', label: 'Last 14 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'Last 60 Days', label: 'Last 60 Days' },
    { value: 'Last 90 Days', label: 'Last 90 Days' },
    { value: 'Custom Date Range', label: 'Custom Date Range' }]

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    //if(window.innerHeight<=568) return
    let marginAndPaddings: any = 80
    let navHeight = localStorage.getItem('navHeight')
    let filtersHeight
    let paginationHeight
    if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
  };

  const onChangePayload = (value: any) => {
    let obj = { ...searchObject };
    if (dropdownvalue === "Battery ID") {
      obj.batteryId = value.trim();
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Battery Rx Serial Number") {
      obj.batteryRxSim = value.trim();
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Vehicle ID") {
      obj.vehicleId = value.trim();
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    }
    setemptyError("")
  }
  const callCountAndReport = (obj: any) => {
    setTableReqBody({ ...tableReqBody, ...obj })
    setExportReqBody({ ...exportReqBody, ...obj })
    setCountReq({ ...countReq, ...obj })
  };

  // Search Enter Button
  const onPressEnter = (event: any) => {
    let value = event.target.value.trim()
    let obj = { ...searchObject };
    switch (dropdownvalue) {
      case 'Battery ID':
        obj.batteryId = value;
        break;
      case 'Battery Rx Serial Number':
        obj.batteryRxSim = value;
        break;
      case 'Vehicle ID':
        obj.vehicleId = value;
        break;
    }
    setSearchObject({ ...obj })
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, ...obj, pageOffset: 1 })
  };

  const handleSearch = () => {
    const { batteryId, vehicleId, batteryRxSim, serialNumber } = searchObject
    let obj = { ...searchObject };
    switch (dropdownvalue) {
      case 'Battery ID':
        if (!batteryId) {
          setemptyError("Please Enter Battery ID")
          return
        } else {
          obj.batteryId = inputvalue.trim();
        }
        break;
      case 'Battery Rx Serial Number':
        if (!batteryRxSim) {
          setemptyError("Please Enter Battery Rx Serial Number")
          return
        } else {
          obj.batteryRxSim = inputvalue.trim();
        }
        break;
      // case 'Battery Rx Serial Number':
      //   if (!serialNumber) {
      //     setemptyError("Please Enter Battery RX Serial Number")
      //     return
      //   } else {
      //     obj.serialNumber = inputvalue.trim();
      //   }
      //   break;
      case 'Vehicle ID':
        if (!vehicleId) {
          setemptyError("Please Enter Vehicle ID")
          return
        } else {
          obj.vehicleId = inputvalue.trim();
        }
        break;
      default:
        setemptyError("")
    }
    setSearchObject({ ...obj })
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, ...obj, pageOffset: 1 })
  };

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
    }
  }, [value])

  const selectorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    getFirmHistoryReport()
    dataCount()
  }, [tableReqBody])

  // useEffect(() => {
  //   if (refresh === 0) return
  //   setTableReqBody({ ...tableReqBody })
  //   setCountReq({ ...countReq })
  // }, [refresh])

  const getFirmHistoryReport = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let payload = tableReqBody;

    if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
    if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();
    if (payload?.serialNumber === "") payload.serialNumber = data?.serialId;

    if (payload.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.FirmHistoryReport.getFirmHistory,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTableReport(response)
      setIsShowSpinner(false)
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }

  const dataCount = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let payload = tableReqBody;

    if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
    if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();
    if (payload?.serialNumber === "") payload.serialNumber = data?.serialId;

    if (payload.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.FirmHistoryReport.getFirmHistoryCount,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        data: payload
      })
      setCount(response)
    } catch (error) {
      console.log(error)
    }
  }

  const onSelect = (pageLimit: any) => {
    const numPageSize = Number(pageLimit)
    setCurrent(1)
    setPageSize(numPageSize)
    setTableReqBody({ ...tableReqBody, pageLimit, pageOffset: 1 })
  };

  const handlePagination = (pageOffset: number, c: number, maxIndex: number) => {
    setTableReqBody({ ...tableReqBody, pageOffset })
    setMaxindex(maxIndex)
    setMindex(minIndex)
    setCurrent(pageOffset)
  }

  const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  const daysFilter = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    const isLeapYearAtStart = isLeapYear(startYear);
    const isLeapYearAtEnd = isLeapYear(endYear);
    const Difference_In_Time = end.getTime() - start.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
    if (Difference_In_Days > maxDays) {
      setModalMessage(`The maximum range allowed is 1 year.`);
      setIsModalVisible(true);
      return;
    }
    setTableReqBody({ ...tableReqBody, startDate, endDate });
  };

  const handleModalClose = () => {
    onChange([subDays(new Date(), 6), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  const selectColumn = () => {
    if (selectorRef.current && selector) {
      selectorRef.current.style.right = "-370px";
    } else {
      if (selectorRef.current) { selectorRef.current.style.right = "0px" }
    }
    setSelector(!selector);
  };

  const applyChanges = async () => {
    selectColumn()
    var columnPayload = {
      reportId: 'FIRMWARE_HISTORY_REPORT',
      reportName: 'FIRMWARE_HISTORY_REPORT',
      columnSettings: selectorLabel,
      sortColumn: tableReqBody?.sortColumns,
      sortDirection: tableReqBody?.sortDirection
    };
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;
    setIsShowSpinner(true);
    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}`,
        method: "PUT",
        headers: { Authorization: "Bearer " + authToken },
        data: columnPayload
      })
      setIsShowSpinner(false)
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn([...columnData])
      setSelectorLabel([...selectorData])
    }
    catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  };


  const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    let columndata: any = selectorLabel
    let index: number = parseInt(event.target.name);
    let checked: boolean = event.target.checked;
    columndata[index].enabled = checked
    setSelectorLabel([...columndata])
    setClearAll(false)
    setSelectAll(false)
  };

  const clearall = () => {
    let columnLable = selectorLabel
    for (let i = 0; i < columnLable.length; i++) {
      columnLable[i]['enabled'] = false;
    }
    setSelectorLabel([...columnLable])
    setClearAll(true)
    setSelectAll(false)
  };

  const selectall = () => {
    let columnLable = selectorLabel
    for (let i = 0; i < columnLable.length; i++) {
      columnLable[i]['enabled'] = true;
    }
    setSelectorLabel(columnLable)
    setClearAll(false)
    setSelectAll(true)
  }

  const cancelSelection = () => {
    let columnData = selectorLabel;
    for (let i = 0; i < columnData.length; i++) {
      columnData[i]['enabled'] = columnData[i].enabled;
    }
    setSelectorLabel(columnData)
    selectColumn()
  };

  const getReportSettings = async () => {
    const reportId = 'FIRMWARE_HISTORY_REPORT'
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;

    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
        method: "GET",
        headers: { Authorization: "Bearer " + authToken },
      })
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn(columnData)
      setSelectorLabel(selectorData)

      setTableReqBody({ ...tableReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setExportReqBody({ ...exportReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setCountReq({ ...countReq, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setInitial(false)
    }
    catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    if (action !== "") {
      getDowFormat(action)
    }
  }, [action])

  const getDowFormat = async (format: string) => {
    if (selectorLabel.some((item: any) => item.enabled == true)) {
      let userInfo: any = Utility.getUserInfo();
      let token = userInfo.authToken
      setIsShowSpinner(true);
      let payload = {
        orgId: tableReqBody?.orgId,
        divisionId: tableReqBody?.divisionId,
        locationId: tableReqBody?.locationId,
        buildingId: tableReqBody?.buildingId,
        startDate: tableReqBody?.startDate,
        endDate: tableReqBody?.endDate,
        batteryId: tableReqBody?.batteryId,
        vehicleId: tableReqBody?.vehicleId,
        serialNumber: tableReqBody?.serialNumber,
        batteryRxSim: tableReqBody?.batteryRxSim,
        pageOffset: 1,
        pageLimit: count + 1,
        sortColumns: tableReqBody?.sortColumns,
        sortDirection: tableReqBody?.sortDirection,
        timeZone: tableReqBody?.timeZone
      }
      // if (searchObject?.batteryId !== "") {
      //   payload.batteryId = searchObject?.batteryId.trim();
      // } 

      // if (searchObject?.vehicleId !== "") {
      //   payload.vehicleId = searchObject?.vehicleId.trim();
      // } 

      // if (searchObject?.batteryRxSim !== "") {
      //   payload.batteryRxSim = searchObject?.batteryRxSim.trim();
      // } 

      try {
        const response = await service({
          url: constantUrl.api.FirmHistoryReport.getFirmHistoryExport + '/' + format,
          method: 'POST',
          responseType: 'blob',
          headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
          data: payload
        })
        if (response) {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Firmware-History-Report.' + format;
          a.click();
        }
        setIsShowSpinner(false);
      } catch (error) {
        setIsShowSpinner(false);
        console.log(error)
      }
    }
    else {
      setAlert(true)
    }
  }

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case "batteryId":
        if (sort.batteryId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "vehicleId":
        if (sort.vehicleId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "rxSim":
        if (sort.rxSim) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryRxSim", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryRxSim", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "oldApp":
        if (sort.oldApp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "oldAppVersion", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "oldAppVersion", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "NewApp":
        if (sort.NewApp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "newAppVersion", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "newAppVersion", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "imgFile":
        if (sort.imgFile) {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageFileSize", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageFileSize", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "terminated":
        if (sort.terminated) {
          setTableReqBody({ ...tableReqBody, sortColumns: "terminated", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "terminated", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "completed":
        if (sort.completed) {
          setTableReqBody({ ...tableReqBody, sortColumns: "completed", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "completed", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "bytesSent":
        if (sort.bytesSent) {
          setTableReqBody({ ...tableReqBody, sortColumns: "bytesSent", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "bytesSent", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "bytesPerSec":
        if (sort.bytesPerSec) {
          setTableReqBody({ ...tableReqBody, sortColumns: "bytesPerSec", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "bytesPerSec", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "totalRetries":
        if (sort.totalRetries) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalRetries", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalRetries", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "imgName":
        if (sort.imgName) {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageFilename", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageFilename", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "startDate":
        if (sort.startDate) {
          setTableReqBody({ ...tableReqBody, sortColumns: "startDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "startDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "finishDate":
        if (sort.finishDate) {
          setTableReqBody({ ...tableReqBody, sortColumns: "finishDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "finishDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "org":
        if (sort.org) {
          setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "div":
        if (sort.div) {
          setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "loc":
        if (sort.loc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "site":
        if (sort.site) {
          setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('firmwarehistory_W')) window.history.back()
    }

    getReportSettings();
    setInitial(true)

  }, [])

  useEffect(() => {
    if (initial == false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setInput("");
      const data = {
        batteryId: '',
        vehicleId: '',
        batteryRxSim: ''
      }
      setSearchObject(data)
      setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
      setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
      setCountReq({ ...countReq, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
      setCurrent(1)
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId, globalData?.serialId])

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
    return formatDate
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div ref={filtersRef} className="posi_search_container">
        <div className="col-md-6 px-0 drop_search-main">
          <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
            <div className='ant-design-select'>
              <Select
                value={dropdownvalue}
                size={'middle'}
                onChange={onChangedropdown}
                className='drop-filter'
                dropdownStyle={{ zIndex: 900 }}
              >
                {DropList && DropList.map((item: any, index: any) => {
                  return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                })}
              </Select>
            </div>
            <div>
              <Space direction="vertical" size="middle" className='search-input'>
                <Space.Compact className='posi_bmid_search_width'>
                  <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                    onPressEnter={onPressEnter}
                  />
                  <Button type="primary" onClick={handleSearch}>Find</Button>
                </Space.Compact>
              </Space>
              <div className="errorColor">{emptyError}</div>
            </div>
          </ConfigProvider>
        </div>
        <div className="column-date-filter position-relative">
          {
            showDateFilter && <span className="date-selector">
              <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
            </span>
          }
          <button
            onClick={selectColumn}
            type="button"
            className="btn btn-light selector-btn"
          >
            Column Selector
          </button>
          <span className="date-dropdown">
            <DaysFilter
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 7 Days'}
            />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
        </div>
      </div>
      <div className="main-panel pt-3 position-relative">
        <div className="ChartPanel panelShadow bg-white">
          <div className="chartTitle p-2 d-flex  align-items-center position-relative">
            <div className='table_title'>Firmware History Report</div>
            <div className='show-entries'>
              <ShowEntries getPageSize={onSelect} />
            </div>
          </div>
          <div id="table-container">
            <div style={{ height: tableHeight + 'px' }} className="overflow-auto scrollbar">
              {/*className="firmhis_report" */}
              <table className="billable width100">
                <thead className='tableHeader'>
                  <tr className="billable-head">
                    {column?.[0]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[0]?.['columnName']}</span>
                        {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                      : ""}
                    {column?.[1]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[1]?.['columnName']}</span>
                        {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[2]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('rxSim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[2]?.['columnName']}</span>
                        {sort.rxSim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[3]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('oldApp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[3]?.['columnName']}</span>
                        {sort.oldApp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[4]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('NewApp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[4]?.['columnName']}</span>
                        {sort.NewApp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[5]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('imgFile')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[5]?.['columnName']}</span>
                        {sort.imgFile ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[6]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('terminated')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[6]?.['columnName']}</span>
                        {sort.terminated ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[7]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('completed')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[7]?.['columnName']}</span>
                        {sort.completed ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {/* {column?.[8]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('bytesSent')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[8]?.['columnName']}</span>
                        {sort.bytesSent ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""} */}
                    {column?.[8]?.['enabled'] ? <th>
                      <span >{column?.[8]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[9]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('bytesPerSec')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[9]?.['columnName']}</span>
                        {sort.bytesPerSec ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[10]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('totalRetries')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[10]?.['columnName']}</span>
                        {sort.totalRetries ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[11]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('imgName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[11]?.['columnName']}</span>
                        {sort.imgName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[12]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('startDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[12]?.['columnName']}</span>
                        {sort.startDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[13]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('finishDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[13]?.['columnName']}</span>
                        {sort.finishDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[14]?.['enabled'] ? <th>
                      <span >{column?.[14]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[15]?.['enabled'] ? <th>
                      <span >{column?.[15]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[16]?.['enabled'] ? <th>
                      <span >{column?.[16]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[17]?.['enabled'] ? <th>
                      <span >{column?.[17]?.['columnName']}</span>
                    </th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {tableReport?.map((row: any, index: any) => {
                    const { batteryId, vehicleId, batteryRxSim, oldAppVersion, newAppVersion, imageFileSize, terminated, completed,
                      bytesSent, bytesPerSec, totalRetries, imageFilename, startDate, finishDate, organization, division, location, building,
                      formatStartDate, formatFinishDate } = row
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          {column?.[0]?.['enabled'] ? <td>{batteryId}</td> : ""}
                          {column?.[1]?.['enabled'] ? <td>{vehicleId}</td> : ""}
                          {column?.[2]?.['enabled'] ? <td>{batteryRxSim}</td> : ""}
                          {column?.[3]?.['enabled'] ? <td>{oldAppVersion}</td> : ""}
                          {column?.[4]?.['enabled'] ? <td>{newAppVersion}</td> : ""}
                          {column?.[5]?.['enabled'] ? <td>{imageFileSize}</td> : ""}
                          {column?.[6]?.['enabled'] ? <td>{terminated}</td> : ""}
                          {column?.[7]?.['enabled'] ? <td>{completed}</td> : ""}
                          {column?.[8]?.['enabled'] ? <td>{bytesSent}</td> : ""}
                          {column?.[9]?.['enabled'] ? <td>{bytesPerSec}</td> : ""}
                          {column?.[10]?.['enabled'] ? <td>{totalRetries}</td> : ""}
                          {column?.[11]?.['enabled'] ? <td>{imageFilename}</td> : ""}
                          {column?.[12]?.['enabled'] ? <td>{formatStartDate}</td> : ""}
                          {column?.[13]?.['enabled'] ? <td>{formatFinishDate}</td> : ""}
                          {column?.[14]?.['enabled'] ? <td>{organization}</td> : ""}
                          {column?.[15]?.['enabled'] ? <td>{division}</td> : ""}
                          {column?.[16]?.['enabled'] ? <td>{location}</td> : ""}
                          {column?.[17]?.['enabled'] ? <td>{building}</td> : ""}
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div ref={paginationRef}>
              <PaginationComponent
                handlePagination={handlePagination}
                totalLength={count}
                pageSize={pageSize}
                current={current}
              />
            </div>
          </div>

        </div>
        <div ref={selectorRef} className="column-selector selector ">
          <div className="column-title">Column Selector</div>
          <div className="slector-body">
            <label>
              <input
                checked={clearAll}
                value="clearAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={clearall}
              />
              <span></span>
            </label>
            <p>Clear All</p>

            <label>
              <input
                checked={selectAll}
                value="selectAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={selectall}
              />
              <span></span>
            </label>
            <p>Select All</p>
          </div>
          <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
            {selectorLabel?.map((item: any, index: any) => {
              return (
                <div key={'index' + index} className="slector-body">
                  <label>
                    <input
                      name={index}
                      onChange={onchangeColumn}
                      checked={item['enabled']}
                      value={item.value}
                      type="checkbox"
                      aria-label="checkbox"
                    />
                    <span></span>
                  </label>
                  <p>{item['columnName']}</p>
                </div>
              )
            })}
          </div>
          <div className="m-flex">
            <button onClick={cancelSelection} className="cancel">
              Cancel
            </button>
            <button onClick={applyChanges} className="apply-changes mr-3">
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={alert}
        closable={false}
        centered title={"Atleast Select 1 Column"}
        footer={[
          <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
        ]}
      ></Modal>
      <SessionWarning />
    </>
  )
}
export default FirmHistoryReport